import React, { useState } from "react";
import Popup from "../../../components/popup/Popup";
import AdditionalInformationForm from "../forms/AdditionalInformationForm";
import { useAppSelector } from "../../../config/hooks";
import useCountryCodes from "../../../utils/dropdown-options/useCountryCodes";
import useSalaries from "../../../utils/dropdown-options/useSalaries";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../utils/dropdown-options/useLocations";

const AdditionalInformationSection = () => {
  const [togglePopup, setTogglePopup] = useState(false);
  const { defaultCountryCode } = useCountryCodes();

  const additionalDetails = useAppSelector(
    (state) => state.candidateProfile.userAdditionalInfo
  );
  const { defualtSalary } = useSalaries();
  const { defaultIndustry } = useIndustries();
  const { defaultLocation } = useLocations();

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };
  const closePopupHandler = () => {
    setTogglePopup(false);
  };

  let defaultCode;
  let defaultSal;
  let defaultIndust;
  let defaultLoc;

  if (additionalDetails?.id) {
    defaultCode = defaultCountryCode(additionalDetails?.countryCode);
    defaultSal = defualtSalary(additionalDetails.lastSalary);
    defaultIndust = defaultIndustry(additionalDetails?.preferredIndustry[0]);
    defaultLoc = defaultLocation(additionalDetails?.preferredLocation[0]);
  }

  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Additional Information
          </h1>
          <button
            type="button"
            className="text-sm text-expurple-800 font-medium"
            onClick={togglePopupHandler}
          >
            <span className="font-bold text-lg">+</span> Add information
          </button>
        </div>

        {additionalDetails?.id && (
          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Additional Phone No. :
              </h1>
              <p className="text-base text-exgray-400 font-semibold">{`+ ${defaultCode?.PhoneCode} ${additionalDetails.mobile}`}</p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Additional Email ID :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails?.email}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Last Salary :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultSal?.Title}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Preferred Industry :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultIndust?.Name}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Preferred Location :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {defaultLoc?.Name}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Open for Relocation :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.relocation ? "Yes" : "No"}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                Serving Noticing Period :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.isServingNoticePeriod ? "Yes" : "No"}
              </p>
            </div>
            <div className="flex items-center justify-start gap-2">
              <h1 className="text-base text-expurple-800 font-semibold">
                No of Serving :{" "}
              </h1>
              <p className="text-base text-exgray-400 font-semibold">
                {additionalDetails.isServingNoticePeriod
                  ? additionalDetails.noticePeriod
                    ? additionalDetails.noticePeriod
                    : "-"
                  : "0"}
              </p>
            </div>
          </div>
        )}
      </div>

      <Popup onClose={closePopupHandler} visible={togglePopup}>
        <AdditionalInformationForm onClose={closePopupHandler} />
      </Popup>
    </div>
  );
};

export default AdditionalInformationSection;
