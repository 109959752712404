import React, { useEffect, useState } from "react";

import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import { Link } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import {
  getAssignJobsToMemberApi,
  getRecommendedJobApi,
} from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../constant";
import { getAllReferralsApi } from "../../../slices/referSlice";

const RecruiterJobsList = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const jobCards = useAppSelector((state) => state.jobs.jobCards);
  const refferredJobsSelector = useAppSelector(
    (state) => state.refer.refferedJobs
  );

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllReferralsApi({ recruiterId: userId })).then(
          unwrapResult
        );
      } catch (error) {
        console.log("error :>> ", error);
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div className="space-y-2">
      {!isLoading && (
        <div className="flex flex-wrap mx-auto">
          {jobCards?.jobs?.length > 0 &&
            jobCards.jobs.map((job) => (
              <div className="w-[33.3333333%]" key={uuid4()}>
                <Link to={`/job/${job.id}`}>
                  <JobCard job={job} />
                </Link>
              </div>
            ))}
        </div>
      )}

      {!refferredJobsSelector.isloading && (
        <div>
          {refferredJobsSelector?.jobs?.referrals &&
            refferredJobsSelector?.jobs?.referrals.length > 0 && (
              <div className="flex flex-wrap mx-auto">
                {refferredJobsSelector?.jobs?.referrals.length > 0 &&
                  refferredJobsSelector?.jobs?.referrals.map((job) => (
                    <div className="w-[33.3333333%]" key={uuid4()}>
                      <Link to={`/job/${job.job_details.id}`}>
                        <JobCard job={job.job_details} />
                      </Link>
                    </div>
                  ))}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default RecruiterJobsList;
