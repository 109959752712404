import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import { updateUserProfileApi } from "../../../slices/candidateProfileSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const SkillsForm = ({ onClose = () => {} }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.session.userId);
  const userSkills = useAppSelector(
    (state) => state.candidateProfile?.userDetails?.skills
  );
  const [skills, setSkills] = useState(userSkills || []);
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const fieldValue = e.target.value;
    setValue(fieldValue);
  };

  const addSkillHandler = () => {
    setSkills((prevData) => [...prevData, value]);
    setValue("");
  };

  const removeSkillHandler = (index) => {
    const newSkillsArray = skills?.filter((_, i) => i !== index);
    setSkills(newSkillsArray);
  };

  const onSaveHandler = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        updateUserProfileApi({
          userId: userId,
          skills: skills,
        })
      ).then(unwrapResult);
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <div className="w-[50%] max-h-[90%] bg-expurple-200 p-10 rounded-xl overflow-y-auto no-scrollbar space-y-2">
      <div className="space-y-2">
        <div className="space-y-2">
          <h1 className="text-2xl text-expurple-800 font-semibold tracking-wide">
            Skills
          </h1>
          <div className="space-y-1">
            <label
              htmlFor={"skills"}
              className={`text-base font-normal text-expurple-800`}
            >
              Add Skills
            </label>
            <div className="relative">
              <input
                type="text"
                className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
                id="skills"
                placeholder="Add skill"
                autoComplete="off"
                name="skills"
                onChange={onChange}
                value={value}
              />
              <div className="absolute h-full top-0 flex items-center justify-center right-2">
                <button
                  type="button"
                  disabled={!value}
                  onClick={addSkillHandler}
                  className="py-1 w-fit px-4  text-expurple-800 font-bold underline text-sm rounded-sm"
                >
                  Add
                </button>
              </div>
            </div>
            {error && <p className="text-red-400">{error}</p>}
          </div>
        </div>
        {skills.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {skills?.map((skill, index) => (
              <div
                className="px-3 py-1 bg-expurple-800 text-golden w-fit rounded-md text-sm flex items-center justify-center gap-4"
                key={uuidv4()}
              >
                <h1>{skill}</h1>
                <div
                  className="cursor-pointer"
                  onClick={() => removeSkillHandler(index)}
                >
                  <CrossIcon width="10" height="10" />
                </div>
              </div>
            ))}
          </div>
        )}

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              type="button"
              className={`px-8 py-2 ${
                isLoading ? "bg-exgray-400/50" : "bg-expurple-800"
              } text-center text-base font-semibold text-exwhite-100 rounded-lg`}
              onClick={onSaveHandler}
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsForm;
