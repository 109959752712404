import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/Icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getCandidatesApi,
  getCandidatesByUrlApi,
} from "../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import RightArrowIcon from "../../assets/Icons/RightArrowIcon";
import LocationIcon from "../../assets/Icons/LocationIcon";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import { v4 as uuidV4 } from "uuid";
import * as constants from "../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";

const JobSeekers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchRef = useRef(null);

  // const [searchTerm, setSearchTerm] = useState("");

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const candidateListSelector = useAppSelector(
    (state) => state?.refer?.candidateList?.candidates
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("loginSelector.who :>> ", loginSelector.who);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          getCandidatesApi({
            authorId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? loginSelector.userId
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : loginSelector.userId,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigationHandler = (path) => {
    navigate(path);
  };

  const onChangeSearchHandler = (e) => {
    if (!e.target.value) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          await dispatch(
            getCandidatesApi({
              authorId:
                loginSelector.who === constants.COMPANY.toLowerCase()
                  ? loginSelector.userId
                  : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                  ? memberSelector.companyId
                  : loginSelector.userId,
            })
          ).then(unwrapResult);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  };

  const searchCandidateHandler = async () => {
    if (searchRef.current) {
      console.log("searchRef.current.value :>> ", searchRef.current.value);
      setIsLoading(true);
      try {
        await dispatch(
          getCandidatesApi({
            authorId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? loginSelector.userId
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : loginSelector.userId,
            s: searchRef.current.value,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getMoreDataHandler = () => {
    dispatch(getCandidatesByUrlApi());
  };

  return (
    <div className="w-[95%] mx-auto py-7">
      <div className="py-4 flex items-center justify-between">
        <p className="text-expurple-800 text-lg font-semibold">{`${
          candidateListSelector?.user ? candidateListSelector?.user?.length : 0
        } JobSeekers`}</p>
        <div>
          <button
            className="bg-transparent px-10 py-2 text-expurple-800 text-base font-semibold"
            onClick={() => navigationHandler("/company-admin")}
          >
            Back
          </button>
          <button
            className="px-6 py-2 text-base text-exwhite-100  rounded-md font-semibold w-fit  bg-expurple-800"
            onClick={() => navigationHandler("/bulk-upload")}
          >
            Create
          </button>
        </div>
      </div>

      <div>
        <div className="w-[40%] relative">
          <input
            type="text"
            ref={searchRef}
            autoComplete="off"
            className="w-full h-12 pl-10 pr-4 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
            placeholder="Search"
            name="search"
            onChange={onChangeSearchHandler}
          />
          <div className="h-full absolute top-0 flex items-center justify-center left-2">
            <SearchIcon />
          </div>
          <div className="h-full absolute top-0 flex items-center justify-center right-2">
            <button
              className="px-6 py-1 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md active:scale-90"
              type="button"
              onClick={searchCandidateHandler}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="py-2">
        {!isLoading &&
        candidateListSelector?.user &&
        candidateListSelector?.user?.length > 0 ? (
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={
              candidateListSelector?.user?.length
                ? candidateListSelector?.user?.length
                : 0
            }
            next={() => getMoreDataHandler()}
            hasMore={candidateListSelector?.metadata?.nextResultURL}
            // height={"calc(100vh - 155px)"}
            loader={""}
          >
            {candidateListSelector?.user.map((candidateDetails) => (
              <div
                className="flex items-center justify-between p-4 bg-transparent gap-2 border-b border-b-exgray-100"
                key={uuidV4()}
              >
                <div className="flex items-center justify-start gap-20">
                  <Link
                    to={`/job-seekers/${candidateDetails?.id}`}
                    className="flex items-center justify-start gap-5 cursor-pointer"
                  >
                    {false ? (
                      <div className="h-28 w-28  rounded-lg flex items-center justify-center overflow-hidden">
                        <img
                          //   src={`${constants.baseUrl}/files/${candidateDetails?.picUrl}?t=${token}`}
                          alt="profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ) : (
                      <div className="h-28 w-28 rounded-lg bg-DefaultProfileImg bg-center bg-cover bg-no-repeat"></div>
                    )}

                    <div className="w-60 space-y-1">
                      <h1 className="text-lg text-exgray-800 font-semibold leading-none">
                        {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
                      </h1>
                      <p className="text-base text-exgray-400">
                        {candidateDetails?.designation}
                      </p>
                      <div className="flex items-center justify-start gap-2">
                        <p className="text-expurple-700 text-base font-semibold line-clamp-1">
                          {candidateDetails?.organization
                            ? candidateDetails?.organization
                            : "N/A"}
                        </p>
                        <LocationIcon width="20" height="20" color="#5071C4" />
                        <p className="text-expurple-800 text-base font-semibold line-clamp-1">
                          Vashi
                        </p>
                      </div>

                      <p className="text-base text-exgray-500 font-semibold line-clamp-1">
                        IT
                      </p>
                    </div>
                  </Link>
                  <p className="text-exgray-800 text-base font-semibold">
                    {candidateDetails?.totalExperience
                      ? `${candidateDetails?.totalExperience} Yrs Exp`
                      : "N/A"}
                  </p>
                </div>

                {/* <p className="text-base text-exgray-800">In progress</p> */}

                <div className="flex items-center justify-end gap-20">
                  <button className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90">
                    Download
                    <div className="rotate-90">
                      <RightArrowIcon color="#0BA02C" />
                    </div>
                  </button>

                  <div className="relative">
                    <div className="cursor-pointer">
                      <VerticleThreeDotsIcon />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : candidateListSelector?.user &&
          candidateListSelector?.user?.length === 0 ? (
          <div>
            <div className="flex items-center justify-center pt-20">
              <NoDataFoundIcon />
            </div>
            <div>
              <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                No Data Found
              </h1>
              <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                Find new opportunities and manage your candidate search progress
                here.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center py-20">
            <LoadingIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobSeekers;
