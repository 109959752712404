import React, { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../config/hooks";

import {
  getCandidateDetailApi,
  updateUserProfileApi,
} from "../../slices/candidateProfileSlice";

import UploadIcon from "../../assets/Icons/UploadIcon";
import PersonalDetails from "./components/PersonalDetails";
import ExperienceSection from "./components/ExperienceSection";
import EducationSection from "./components/EducationSection";
import SkillSection from "./components/SkillSection";

import "../../App.css";
import AdditionalInformationSection from "./components/AdditionalInformationSection";
import uploadContent from "../../utils/upload-content/uploadContent";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../constant";
import StandardEditIcon from "../../assets/Icons/StandardEditIcon";
import PreferenceSection from "./components/PreferenceSection";
import { useNavigate } from "react-router-dom";

const CandidateProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const imageInputRef = useRef(null);
  const submitBtnRef = useRef(null);

  const userId = useAppSelector((state) => state.login?.session?.userId);
  const token = useAppSelector((state) => state.login?.session?.token);
  const userDetails = useAppSelector(
    (state) => state.candidateProfile.userDetails
  );
  const apiAsyncData = useAppSelector((state) => state.candidateProfile);

  const { isLoading, error } = apiAsyncData;

  useEffect(() => {
    dispatch(getCandidateDetailApi({ userId }));
  }, []);

  const handleClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const savePersonalDetailsHandler = () => {
    if (submitBtnRef.current) submitBtnRef.current.click();
  };

  const navigateHandler = () => {
    navigate("/applied-jobs");
  };

  const onChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadContent.uploadProfileContent(userId, e.target.files, (response) => {
        const url = response[0].url;
        dispatch(
          updateUserProfileApi({
            userId: userId,
            profilePicture: url,
          })
        )
          .then(unwrapResult)
          .then((response) => {})
          .catch((error) => {});
      });
    }
  };

  if (error) {
    return <h1 className="text-red-950 text-3xl">Somthing went wrong</h1>;
  } else {
    return (
      !isLoading && (
        <div className="">
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-between py-4">
              <h1 className="text-3xl text-exgray-300/80 tracking-wide font-semibold">
                Edit Profile
              </h1>

              <div className="flex items-center justify-center gap-2">
                <button
                  className="px-10 py-2 text-center text-base font-semibold rounded-lg bg-exgray-100 text-expurple-800"
                  onClick={navigateHandler}
                >
                  Back
                </button>
                <button
                  className="px-10 py-2 text-center text-base font-semibold rounded-lg text-exwhite-100 bg-expurple-800"
                  type="button"
                  onClick={savePersonalDetailsHandler}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-[300px] space-y-2 self-start">
                <h6 className="text-black text-base tracking-wide font-semibold leading-none">
                  Profile Picture
                </h6>

                {userDetails.profilePicture ? (
                  <div className="w-full h-80 rounded-lg flex items-center justify-center overflow-hidden relative">
                    <img
                      src={`${constants.baseUrl}/files/${userDetails.profilePicture}?t=${token}`}
                      alt="profile"
                      className="w-full h-full object-cover"
                    />
                    <div
                      className="h-10 w-10 bg-expurple-100 absolute bottom-2 right-2 flex items-center justify-center rounded-lg cursor-pointer"
                      onClick={handleClick}
                    >
                      <StandardEditIcon color="#223870" />
                      <input
                        ref={imageInputRef}
                        type="file"
                        name="candidateProfileImage"
                        multiple
                        accept="image/*"
                        onChange={onChangeHandler}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-80 border-2 border-dashed border-exgray-200 rounded-lg flex items-center justify-center">
                    <div className="text-center w-[90%] mx-auto">
                      <div
                        className="cursor-pointer w-fit mx-auto"
                        onClick={handleClick}
                      >
                        <div>
                          <UploadIcon />
                        </div>
                        <input
                          ref={imageInputRef}
                          type="file"
                          name="image"
                          multiple
                          accept="image/*"
                          onChange={onChangeHandler}
                          style={{ display: "none" }}
                        />
                      </div>
                      <h2 className="text-lg text-black font-semibold">
                        Browse Photo
                      </h2>
                      <p className="text-base text-exgray-500">
                        A photo larger than 400 pixels work best. Max photo size
                        5 MB.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex-1 pl-4 space-y-2 pb-4">
                <div className="border-b-2 border-b-exgray-200 pb-4">
                  <PersonalDetails submitBtnRef={submitBtnRef} />
                </div>

                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <ExperienceSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <EducationSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <SkillSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <PreferenceSection />
                </div>
                <div className="pb-2">
                  <AdditionalInformationSection />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
};

export default CandidateProfile;
