import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import LocationIcon from "../../../assets/Icons/LocationIcon";
import ClockIcon from "../../../assets/Icons/ClockIcon";
import BookmarkIcon from "../../../assets/Icons/BookmarkIcon";
import IndustryIcon from "../../../assets/Icons/IndustryIcon";
import SalaryRangeIcon from "../../../assets/Icons/SalaryRangeIcon";
import ExperienceIcon from "../../../assets/Icons/ExperienceIcon";
import JobTypeIcon from "../../../assets/Icons/JobTypeIcon";
import WorkPlaceIcon from "../../../assets/Icons/WorkPlaceIcon";
import BulbIcon from "../../../assets/Icons/BulbIcon";
import { v4 as uuid4 } from "uuid";
import * as constants from "../../../constant";
import ApplyJobForm from "./ApplyJobForm";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCompanyDetail } from "../../../slices/companySlice";
import {
  jobOpenedbeforeLoginHandler,
  saveJobApi,
  unSaveJobApi,
  withdrawJobApi,
} from "../../../slices/jobSlice";
import AssignJobForm from "../../company-job-page/components/AssignJobForm";
import ReferCandidateForm from "../../../components/refer-candidate-form/ReferCandidateForm";
import useJobTypes from "../../../utils/dropdown-options/useJobTypes";
import useWorkPlaceTypes from "../../../utils/dropdown-options/useWorkPlaceTypes";
import CreateReferForm from "../../company-job-page/components/CreateReferForm";
import { useLocation, useNavigate } from "react-router-dom";
import useMinExperiences from "../../../utils/dropdown-options/useMinExperiences";
import BookmarkFilledIcon from "../../../assets/Icons/BookmarkFilledIcon";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useLocations from "../../../utils/dropdown-options/useLocations";

const JobDetail = ({
  applicationId = "",
  jobDetails,
  closePopupHandler,
  applied,
  savedJob = false,
  status = "",
  referalId = "",
  recruiterId = "",
  referralType = "",
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { defaultJobType } = useJobTypes();
  const { defaultWorkPlaceType } = useWorkPlaceTypes();
  const { defaultMinExperience } = useMinExperiences();
  const { defaultIndustries } = useIndustries();
  const { defaultLocations } = useLocations();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  const [authorDetails, setAuthorDetails] = useState(null);
  const [error, setError] = useState(false);
  const [toggleApplyForm, setToggleApplyForm] = useState(false);
  const [toggleReferCandidate, setTogglReferCandidate] = useState(false);
  const {
    id,
    title,
    company,
    location,
    createdAt,
    description,
    industries,
    salary,
    hideSalary,
    hideCompany,
    workplaceType,
    experience,
    jobType,
    skills,
    authorType,
    authorId,
  } = jobDetails;

  const token = useAppSelector((state) => state?.login?.session?.token);
  const appliedJobs = useAppSelector((state) => state?.jobs?.appliedJobs);

  const defaultJobTypeObject = useMemo(() => {
    return defaultJobType(jobType);
  }, [jobType]);

  const defaultAorkPlaceTypeObject = useMemo(() => {
    return defaultWorkPlaceType(workplaceType);
  }, [workplaceType]);

  const defaultMinExperObj = useMemo(
    () => defaultMinExperience(experience?.min),
    [experience?.min]
  );

  const defaultMaxExperObj = useMemo(
    () => defaultMinExperience(experience?.max),
    [experience?.max]
  );

  const defaultInd = useMemo(() => {
    return industries && industries.length > 0
      ? defaultIndustries(industries)
      : null;
  }, [industries]);

  const loc = useMemo(() => {
    return location && location.length > 0 ? defaultLocations(location) : null;
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      if (authorType === "COMPANY" && userId) {
        try {
          const authorResponse = await dispatch(
            getCompanyDetail({ id: authorId })
          ).then(unwrapResult);
          setAuthorDetails(authorResponse.data.data);
        } catch (error) {
          setError(true);
        }
      }
    };

    fetchData();
  }, []);

  const isAlredyApplied = useMemo(() => {
    if (appliedJobs && appliedJobs.length > 0 && userId) {
      const isJobApplied = appliedJobs.some((job) => job.jobId === id);
      return isJobApplied;
    } else {
      return false;
    }
  }, [appliedJobs, id]);

  const applicationStatus = useMemo(() => {
    if (appliedJobs && appliedJobs.length > 0 && userId) {
      const isJobApplied = appliedJobs.find((job) => job.jobId === id);
      return isJobApplied?.status;
    } else {
      return "";
    }
  }, [appliedJobs, id]);

  console.log("applicationStatus :>> ", applicationStatus);

  const withDrawJobHandler = async () => {
    if (pathname === "/job-search" && isAlredyApplied) {
      const isJobApplied = appliedJobs.find((job) => job.jobId === id);
      await dispatch(
        withdrawJobApi({ jobId: id, applicationId: isJobApplied.id })
      ).then(unwrapResult);
      closePopupHandler();
    } else {
      await dispatch(
        withdrawJobApi({ jobId: id, applicationId: applicationId })
      ).then(unwrapResult);
      closePopupHandler();
    }
  };

  const toggleApplyFormHandler = () => {
    if (userId) {
      setToggleApplyForm(!toggleApplyForm);
    } else {
      dispatch(jobOpenedbeforeLoginHandler({ jobId: id }));
      navigate("/candidate");
    }
  };

  const toggleReferCandidateandler = () => {
    setTogglReferCandidate(!toggleReferCandidate);
  };

  const saveJobHandler = async () => {
    if (userId) {
      await dispatch(saveJobApi({ userId, jobId: id })).then(unwrapResult);
      closePopupHandler();
    }
  };

  const unSaveJobHandler = async () => {
    if (userId) {
      closePopupHandler();
      try {
        await dispatch(unSaveJobApi({ userId, jobId: id })).then(unwrapResult);
      } catch (error) {}
    }
  };

  const jobRelativeTime = moment(createdAt).fromNow();

  return !toggleApplyForm ? (
    <div className="space-y-4 p-10">
      {applicationStatus && applicationStatus === "REJECTED" && (
        <div className="py-2 px-4 bg-red-200 text-base text-start font-semibold text-red-600 rounded-lg">
          Thank you for your interest, but we have decided to move forward with
          other candidates at this time.
        </div>
      )}

      {applicationStatus && applicationStatus === "SHORTLISTED" && (
        <div className="py-2 px-4 bg-green-200 text-base text-start font-semibold text-green-600 rounded-lg">
          Congratulations! You've been selected for the next round of
          interviews.
        </div>
      )}
      <div className="flex items-center gap-4">
        <div className="h-20 w-20 bg-deafultCompanyProfile rounded-md bg-cover bg-center bg-no-repeat"></div>
        <div className="flex-1 space-y-1 self-stretch">
          <h1 className="text-lg text-black font-semibold line-clamp-1">
            {title}
          </h1>
          <p className="text-base text-exgray-400 font-semibold line-clamp-1">
            {hideCompany ? "Confidential" : company}
          </p>
          <div className="flex items-center justify-start gap-5">
            <h1 className="flex items-center justify-start gap-2 text-base text-exgray-300 line-clamp-1">
              <LocationIcon width="20" height="20" color="#6C7275" />{" "}
              {loc && loc.length > 0
                ? loc.map(
                    (l, i) => `${l.Name} ${loc.length - 1 !== i ? ", " : ""}`
                  )
                : "N/A"}
            </h1>
            <h1 className="flex items-center gap-2 text-base text-exgray-300">
              <ClockIcon width="20" height="20" color="#6C7275" />
              {`Posted ${jobRelativeTime}`}
            </h1>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4">
          {userId &&
            (savedJob ? (
              <button
                className="bg-expurple-100 self-stretch px-2 rounded-sm active:scale-90"
                onClick={unSaveJobHandler}
              >
                <BookmarkFilledIcon />
              </button>
            ) : (
              <button
                className="bg-expurple-100 self-stretch px-2 rounded-sm active:scale-90"
                onClick={saveJobHandler}
              >
                <BookmarkIcon />
              </button>
            ))}
          {(applied && userId) ||
          (pathname === "/job-search" && userId && isAlredyApplied) ? (
            <button
              className="px-8 py-2 text-center text-exwhite-100 text-lg font-semibold rounded-sm bg-expurple-800"
              onClick={withDrawJobHandler}
            >
              Withdraw
            </button>
          ) : loginSelector?.who === "user" ? (
            <button
              className="px-8 py-2 text-center text-exwhite-100 text-lg font-semibold rounded-sm bg-expurple-800"
              onClick={toggleApplyFormHandler}
            >
              Apply
            </button>
          ) : loginSelector?.who === "recruiter" ? (
            <button
              className="px-8 py-2 text-center text-exwhite-100 text-lg font-semibold rounded-sm bg-expurple-800"
              onClick={toggleReferCandidateandler}
            >
              Refer
            </button>
          ) : (
            <button
              className="px-8 py-2 text-center text-exwhite-100 text-lg font-semibold rounded-sm bg-expurple-800"
              onClick={toggleApplyFormHandler}
            >
              Apply
            </button>
          )}
        </div>
      </div>
      {toggleReferCandidate ? (
        <div className="h-[450px]">
          <CreateReferForm jobId={id} onClose={toggleReferCandidateandler} />
        </div>
      ) : (
        <div className="flex items-center justify-center gap-2">
          <div className="flex-1 self-stretch whitespace-pre-wrap text-base text-exgray-200">
            {description}
          </div>
          <div className="w-[40%] self-stretch space-y-2">
            <div className="border-2 border-exgray-100 p-6 rounded-md space-y-6">
              <div className="grid grid-cols-2 auto-rows-auto gap-y-6">
                <div className="text-start text-base space-y-1">
                  <div>
                    <IndustryIcon />
                  </div>
                  <h1 className="text-exgray-200 text-sm">INDUSTRY</h1>
                  {defaultInd && defaultInd.length > 0
                    ? defaultInd.map((l, i) => (
                        <p
                          className="text-expurple-800 font-semibold"
                          key={uuid4()}
                        >
                          {`${l.Name} ${
                            defaultInd.length - 1 !== i ? ", " : ""
                          }`}
                        </p>
                      ))
                    : "N/A"}
                </div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <SalaryRangeIcon />
                  </div>
                  <h1 className="text-exgray-200 text-sm">SALARY RANGE</h1>
                  {hideSalary ? (
                    <p className="text-expurple-800 font-semibold text-start">
                      Confidential
                    </p>
                  ) : (
                    <p className="text-expurple-800 font-semibold text-start">
                      &#x20b9; {salary?.min} Lakh - &#x20b9; {salary?.max} Lakh
                    </p>
                  )}
                </div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <JobTypeIcon />
                  </div>
                  <h1 className="text-exgray-200 text-sm">JOB TYPE</h1>
                  <p className="text-expurple-800 font-semibold">
                    {defaultJobTypeObject?.Title
                      ? defaultJobTypeObject?.Title
                      : "N/A "}
                  </p>
                </div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <WorkPlaceIcon />
                  </div>
                  <h1 className="text-exgray-200 text-sm">WORK PLACE TYPE</h1>
                  <p className="text-expurple-800 font-semibold">
                    {defaultAorkPlaceTypeObject?.Title
                      ? defaultAorkPlaceTypeObject?.Title
                      : "N/A"}
                  </p>
                </div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <LocationIcon width="24" height="24" />
                  </div>
                  <h1 className="text-exgray-200 text-sm">LOCATION</h1>
                  <div className="flex flex-wrap items-center justify-start gap-1">
                    {loc && loc.length > 0
                      ? loc.map((l, i) => (
                          <p
                            className="text-expurple-800 font-semibold"
                            key={uuid4()}
                          >
                            {`${l.Name} ${loc.length - 1 !== i ? ", " : ""}`}
                          </p>
                        ))
                      : "N/A"}
                  </div>
                </div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <ExperienceIcon />
                  </div>
                  <h1 className="text-exgray-200 text-sm">YRS OF EXPERIENCE</h1>
                  <p className="text-expurple-800 font-semibold">
                    {defaultMinExperObj?.Title} - {defaultMaxExperObj?.Title}
                  </p>
                </div>
              </div>
              <div>
                <div className="text-start text-base space-y-1">
                  <div>
                    <BulbIcon width="32" height="32" />
                  </div>
                  <h1 className="text-exgray-200 text-sm">SKILLS</h1>
                  <div className="text-expurple-800 font-semibold flex flex-wrap gap-2">
                    {skills.length > 0 &&
                      skills.map((skill) => (
                        <p
                          className="bg-expurple-800 px-3 py-2 text-start text-base text-exwhite-100 font-semibold rounded-md"
                          key={uuid4()}
                        >
                          {skill}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {authorDetails && !error && authorType === "COMPANY" && (
              <div className="p-6 border-2 border-exgray-100 rounded-md space-y-3">
                <h1 className="text-exgray-800 text-xl font-semibold text-start">
                  Job Created By
                </h1>
                <div className="flex items-center justify-start gap-4">
                  {authorDetails?.profileImage ? (
                    <img
                      className="h-20 w-20 rounded-full object-cover"
                      src={`${constants.lxBaseUrl}/files/${authorDetails?.profileImage}?t=${constants.token}`}
                      alt={`profile`}
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full border-2 border-exgray-200 flex items-center justify-center bg-samplelogo bg-center bg-no-repeat bg-cover"></div>
                  )}
                  <h1 className="text-xl text-exgray-200 font-semibold">{`${authorDetails?.title}`}</h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="p-10">
      <ApplyJobForm
        jobDetails={jobDetails}
        closePopupHandler={closePopupHandler}
        status={status}
        referalId={referalId}
        recruiterId={recruiterId}
        referralType={referralType}
      />
    </div>
  );
};

export default JobDetail;
