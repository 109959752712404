import React, { useEffect, useMemo, useRef, useState } from "react";
import RightArrowIcon from "../../../assets/Icons/RightArrowIcon";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExCheckBox from "../../../components/basic-components/ExCheckBox";
import useJobTypes from "../../../utils/dropdown-options/useJobTypes";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import useWorkPlaceTypes from "../../../utils/dropdown-options/useWorkPlaceTypes";
import useLocations from "../../../utils/dropdown-options/useLocations";
import useMinExperiences from "../../../utils/dropdown-options/useMinExperiences";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import ExRadioBtnGroup from "../../../components/basic-components/ExRadioBtnGroup";
import useSalaries from "../../../utils/dropdown-options/useSalaries";
import ExTextarea from "../../../components/basic-components/ExTextarea";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  asignJobApi,
  createJobApi,
  getJobDetailApi,
  updateJobApi,
} from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";
import * as constants from "../../../constant";
import uploadContent from "../../../utils/upload-content/uploadContent";
import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import LoadingIcon from "../../../assets/Icons/LoadingIcon";
import { getJdDetailsApi } from "../../../slices/aiSlice";
import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import { v4 as uuidv4 } from "uuid";

const CreateJob = () => {
  const { jobId } = useParams();
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [interestValue, setInterestValue] = useState("");

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const { jobTypesArray, defaultJobType } = useJobTypes();
  const { workplaceTypesArray, defaultWorkPlaceType } = useWorkPlaceTypes();
  const { locationsArray, defaultLocations } = useLocations();
  const { minExperienceArray, defaultMinExperience } = useMinExperiences();
  const { industriesArray, defaultIndustries } = useIndustries();
  const { minSalaries, defualtSalary } = useSalaries();

  const form = useForm({
    defaultValues: async () => {
      if (jobId) {
        const response = await dispatch(getJobDetailApi({ jobId })).then(
          unwrapResult
        );
        const data = response.data.data[0];
        console.log("data :>> ", data);
        return {
          jobTitle: data.title,
          companyName: data.company,
          hideCompany: data.hideCompany,
          jobType: defaultJobType(data.jobType),
          workplaceType: defaultWorkPlaceType(data.workplaceType),
          minExperience: defaultMinExperience(data.experience.min),
          maxExperience: defaultMinExperience(data.experience.max),
          industry: data.industries && defaultIndustries(data.industries),
          location: defaultLocations(data.location),
          hiringFor: data.hiringFor === "COMPANY" ? "Company" : "Client",
          skill: data.skills,
          minSalary: defualtSalary(data.salary.min),
          maxSalary: defualtSalary(data.salary.max),
          hideSalary: data.hideSalary,
          jobDescription: data.description,
          createdAt: data.createdAt,
          jobStatus: data.jobStatus,
          file: {
            name: "",
            url: "",
            contentType: "",
            size: 0,
          },
        };
      } else {
        return {
          jobTitle: "",
          companyName: "",
          hideCompany: false,
          jobType: null,
          workplaceType: null,
          minExperience: null,
          maxExperience: null,
          industry: null,
          location: null,
          hiringFor: "Company",
          skill: [],
          minSalary: null,
          maxSalary: null,
          hideSalary: false,
          jobDescription: "",
          jobStatus: "",
          file: {
            name: "",
            url: "",
            contentType: "",
            size: 0,
          },
        };
      }
    },
  });

  const { handleSubmit, control, formState, watch, setValue } = form;
  const { errors, isDirty } = formState;

  const uploadedFile = watch("file");
  const allSkillsValues = watch("skill");
  const selectedMinExperience = watch("minExperience");
  const selectedMaxExperience = watch("maxExperience");
  const selectedMinSalary = watch("minSalary");
  const selectedMaxSalary = watch("maxSalary");

  const maxExpRange = useMemo(() => {
    if (selectedMinExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMinExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(targetIndex + 1);
      return minExperienceArray;
    }
    return minExperienceArray;
  }, [selectedMinExperience]);

  const minExpRange = useMemo(() => {
    if (selectedMaxExperience) {
      const targetIndex = minExperienceArray.findIndex(
        (exp) => exp.Id === selectedMaxExperience.Id
      );
      if (targetIndex) return minExperienceArray.slice(0, targetIndex);
      else return minExperienceArray;
    }
    return minExperienceArray;
  }, [selectedMaxExperience]);

  const maxSalRange = useMemo(() => {
    if (selectedMinSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.Id === selectedMinSalary.Id
      );
      if (targetIndex) return minSalaries.slice(targetIndex + 1);
      else return minSalaries;
    }
    return minSalaries;
  }, [selectedMinSalary]);

  const minSalRange = useMemo(() => {
    if (selectedMaxSalary) {
      const targetIndex = minSalaries.findIndex(
        (sal) => sal.Id === selectedMaxSalary.Id
      );
      if (targetIndex) return minSalaries.slice(0, targetIndex);
      else return minSalaries;
    }
    return minSalaries;
  }, [selectedMaxSalary]);

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const navigateHandler = () => {
    if (loginSelector.who === "company") {
      navigate("/company-admin");
    } else if (loginSelector.who === "company_member") {
      navigate("/member");
    }
  };

  const getJdDetailsHandler = async (response, onChange) => {
    const formData = new FormData();
    formData.append(
      "jd_file",
      `${constants.baseUrl}/files/${response[0].url}?t=${loginSelector.token}`
    );
    try {
      const res = await dispatch(getJdDetailsApi(formData)).then(unwrapResult);
      console.log("res :>> ", res.data.data);
      const jd = res.data.data;

      if (jd.title) {
        setValue("jobTitle", jd.title);
      }
      if (jd.company) {
        setValue("companyName", jd.company);
      }
      if (jd.skills && jd.skills.length > 0) {
        setValue("skill", jd.skills);
      }
      if (jd.hideCompany) {
        setValue("hideCompany", jd.hideCompany);
      }
      if (jd.hideSalary) {
        setValue("hideSalary", jd.hideSalary);
      }
      if (jd.description) {
        setValue("jobDescription", jd.description);
      }
      if (jd.experience.min) {
        const findExp = minExperienceArray.find((item) =>
          item.Title.includes(jd.experience.min.toString())
        );
        if (findExp) {
          setValue("minExperience", findExp);
        }
      }
      if (jd.experience.max) {
        const findExp = minExperienceArray.find((item) =>
          item.Title.includes(jd.experience.max.toString())
        );
        if (findExp) {
          setValue("minExperience", {
            Id: `${jd.experience.max} Years`,
            Title: `${jd.experience.max} Years`,
          });
        }
      }
      // if (jd.salary.min) {
      //   setValue("minSalary", {
      //     Id: `${jd.salary.min}`,
      //     Title: `${jd.salary.min}`,
      //   });
      // }
      // if (jd.salary.max) {
      //   setValue("maxSalary", {
      //     Id: `${jd.salary.max}`,
      //     Title: `${jd.salary.max}`,
      //   });
      // }
      if (jd.industries && jd.industries.length > 0) {
        const defaultJdIndustries = jd.industries.map((ind) => ({
          Id: ind,
          Name: ind,
        }));
        setValue("industry", defaultJdIndustries);
      }
      if (jd.location && jd.location.length > 0) {
        const namesToMatch = jd.location.map((name) => name.toLowerCase());

        const newArray = locationsArray.filter((location) =>
          namesToMatch.includes(location.Name.toLowerCase())
        );
        // const defaultJdLocations = jd.location.map((loc) => ({
        //   Id: loc,
        //   Name: loc,
        // }));
        if (newArray.length > 0) {
          setValue("location", newArray);
        }
      }
      if (jd.jobType) {
        setValue("jobType", { Id: jd.jobType, Title: jd.jobType });
      }
      if (jd.workplaceType) {
        setValue("workplaceType", {
          Id: jd.workplaceType,
          Title: jd.workplaceType,
        });
      }

      onChange({
        name: response[0].orginalFileName,
        url: response[0].url,
        contentType: response[0].contentType,
        size: response[0].size,
      });
      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
    }
  };

  const onChangeInterestValue = (e) => {
    const fieldValue = e.target.value;
    setInterestValue(fieldValue);
  };

  const addInterestHandler = () => {
    setValue("skill", [...allSkillsValues, interestValue], {
      shouldDirty: true,
    });
    setInterestValue("");
  };

  const removeInterestHandler = (index) => {
    const newInterestArray = allSkillsValues?.filter((_, i) => i !== index);
    setValue("skill", newInterestArray, { shouldDirty: true });
  };

  const onSubmit = async (formData) => {
    let createJobData;
    const industriesArray =
      formData.industry && formData.industry.length > 0
        ? formData.industry.map((ind) => ind.Id.toString())
        : [];

    const locationsArray =
      formData.location && formData.location.length > 0
        ? formData.location.map((loc) => loc.Id.toString())
        : [];
    if (loginSelector.who === constants.COMPANY.toLowerCase()) {
      createJobData = {
        authorId: loginSelector.userId,
        companyId: loginSelector.userId,
        authorType: constants.COMPANY,
        title: formData.jobTitle,
        company: formData.companyName,
        hideCompany: formData.hideCompany,
        qualification: "asdasdasdasd",
        jobType: formData.jobType.Id.toString(),
        workplaceType: formData.workplaceType.Id.toString(),
        experience: {
          min: formData.minExperience.Id,
          max: formData.maxExperience.Id,
        },
        currency: "INR (₹)",
        salary: { min: formData.minSalary.Id, max: formData.maxSalary.Id },
        hideSalary: formData.hideSalary,
        hiringFor: formData.hiringFor.toUpperCase(),
        description: formData.jobDescription,
        industries: industriesArray,
        functions: ["Human Resources and Administration"],
        skills: formData.skill,
        location: locationsArray,
        jobStatus: formData.jobStatus,
      };
    } else if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()) {
      createJobData = {
        authorId: loginSelector.userId,
        companyId: memberSelector.companyId,
        authorType: constants.COMPANY_MEMBER,
        title: formData.jobTitle,
        company: formData.companyName,
        hideCompany: formData.hideCompany,
        qualification: "asdasdasdasd",
        jobType: formData.jobType.Id.toString(),
        workplaceType: formData.workplaceType.Id.toString(),
        experience: {
          min: formData.minExperience.Id,
          max: formData.maxExperience.Id,
        },
        currency: "INR (₹)",
        salary: { min: formData.minSalary.Id, max: formData.maxSalary.Id },
        hideSalary: formData.hideSalary,
        hiringFor: formData.hiringFor.toUpperCase(),
        description: formData.jobDescription,
        industries: industriesArray,
        functions: ["Human Resources and Administration"],
        skills: formData.skill,
        location: locationsArray,
        jobStatus: formData.jobStatus,
      };
    }

    if (jobId) {
      const response = await dispatch(
        updateJobApi({
          ...createJobData,
          jobId: jobId,
          createdAt: formData.createdAt,
        })
      ).then(unwrapResult);
      if (response) navigate(`/job/${jobId}`);
    } else {
      try {
        const response = await dispatch(createJobApi(createJobData)).then(
          unwrapResult
        );
        if (response) {
          if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()) {
            if (
              loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector.designation === constants.JOB_MANEGMENT
            ) {
              try {
                await dispatch(
                  asignJobApi({
                    jobId: response.data.data.id,
                    members: [
                      {
                        email: memberSelector.emailId,
                        memberId: loginSelector?.userId,
                      },
                    ],
                    assignedBy: loginSelector?.userId,
                    assignTo: constants.COMPANY_MEMBER.toLowerCase(),
                  })
                ).then(unwrapResult);
                navigate("/member");
              } catch (error) {
                console.log("error :>> ", error);
              }
            } else {
              navigate("/member");
            }
          } else if (loginSelector.who === constants.COMPANY.toLowerCase()) {
            navigate("/company-admin");
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  return (
    <div className="w-[80%] min-h-screenheight mx-auto flex items-start justify-center gap-2 py-4">
      <div
        className="w-fit rotate-180 self-start mt-1 cursor-pointer"
        onClick={navigateHandler}
      >
        <RightArrowIcon color="#696969" />
      </div>
      <div className="flex-1">
        <h1 className="text-xl text-expurple-600 font-semibold text-start">
          Post a job
        </h1>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
          {!jobId && (
            <div className="w-[50%]">
              <Controller
                name="file"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor="file"
                      className={`text-lg font-normal text-expurple-800`}
                    >
                      Upload JD
                    </label>
                    <div className="hidden">
                      <input
                        // title="Add Resume / CV"
                        placeholder="Browse file"
                        name="file"
                        id="file"
                        type="file"
                        ref={inputRef}
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => {
                          setIsFileLoading(true);
                          uploadContent.uploadJd(
                            loginSelector.userId,
                            e.target.files,
                            (response) => {
                              if (!response[0].error) {
                                getJdDetailsHandler(response, onChange);
                              }
                            }
                          );
                        }}
                        // value={value}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      <div className="flex-1 h-10 bg-expurple-100 px-3 flex items-center justify-start gap-1">
                        <div
                          className="cursor-pointer"
                          onClick={triggerInputfileHanlder}
                        >
                          <PlusCircleIcon />
                        </div>
                        <p className="text-expurple-700 text-base line-clamp-1">
                          {uploadedFile?.name
                            ? uploadedFile?.name
                            : "Browse file. Only pdf"}
                        </p>
                      </div>
                      {isFileLoading && (
                        <div>
                          <LoadingIcon />
                        </div>
                      )}
                    </div>
                    {errors?.file?.message && (
                      <p className="text-red-400">{errors?.file?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
          )}

          {!jobId && (
            <div className="flex items-center justify-center">
              <div className="flex-1 border-2 border-exgray-100/50 rounded-full"></div>
              <h1 className="text-lg text-exgray-200 px-2 w-fit mx-auto">Or</h1>
              <div className="flex-1 border-2 border-exgray-100/50 rounded-full"></div>
            </div>
          )}

          <div>
            <Controller
              name="jobTitle"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Job title is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Ex. React Js Developer"
                  title="Job Title"
                  name="jobTitle"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.jobTitle?.message}
                />
              )}
            />
          </div>

          <div className="space-y-1">
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Enter your company name"
                  title="Company Name"
                  name="companyName"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.companyName?.message}
                />
              )}
            />

            <Controller
              name="hideCompany"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExCheckBox
                  title="You can choose to hide the company name from candidates."
                  name="hideCompany"
                  labelStyle="text-expurple-800 text-base italic"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Advance Information
          </h1>

          <div className="grid grid-cols-3 auto-rows-auto gap-2">
            <div>
              <Controller
                name="jobType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Job type is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Job Type"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={jobTypesArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.jobType?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="workplaceType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Work place type is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Work Place Type"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={workplaceTypesArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.workplaceType?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  // <ExDropdown
                  //   title="Location"
                  //   onChange={onChange}
                  //   placeholder="Select"
                  //   value={value || null}
                  //   optionValue="Id"
                  //   optionLabel="Name"
                  //   options={locationsArray}
                  //   dropDownHeight="40px"
                  //   dropDownBorderWidth="0px"
                  //   dropDownBorderColor="#696969"
                  //   error={errors?.location?.message}
                  // />

                  <ExMultiDropdown
                    title="Location"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={locationsArray}
                    closeMenuOnSelect={false}
                    dropDownHeight="auto"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.location?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="minExperience"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Minimum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minExpRange}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.minExperience?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="maxExperience"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Maximum Experience"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={maxExpRange}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.maxExperience?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="industry"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  // <ExDropdown
                  //   title="Industry"
                  //   onChange={onChange}
                  //   placeholder="Select"
                  //   value={value || null}
                  //   optionValue="Id"
                  //   optionLabel="Name"
                  //   options={industriesArray}
                  //   dropDownHeight="40px"
                  //   dropDownBorderWidth="0px"
                  //   dropDownBorderColor="#696969"
                  //   error={errors?.industry?.message}
                  // />

                  <ExMultiDropdown
                    title="Industry"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={industriesArray}
                    closeMenuOnSelect={false}
                    dropDownHeight="auto"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.industry?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="hiringFor"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Hiring is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExRadioBtnGroup
                    title="Hiring For"
                    onChange={onChange}
                    selectedOption={value}
                    options={["Company", "Client"]}
                    error={errors?.hiringFor?.message}
                  />
                )}
              />
            </div>
          </div>

          {/* <div>
            <Controller
              name="skill"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Skill is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  placeholder="Ex. React Js"
                  title="Skill"
                  name="skill"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.skill?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="skill"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Skill is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="space-y-2">
                  <label
                    htmlFor={"skill"}
                    className={`text-lg text-expurple-800`}
                  >
                    Skills
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
                      id="skill"
                      placeholder="Add Skill"
                      autoComplete="off"
                      name="skill"
                      onChange={onChangeInterestValue}
                      value={interestValue}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        disabled={!interestValue}
                        onClick={addInterestHandler}
                        className="py-1 w-fit px-4  text-expurple-800 font-bold underline text-sm rounded-sm"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {errors?.skill?.message && (
                    <p className="text-red-400">{errors?.skill?.message}</p>
                  )}

                  {allSkillsValues && allSkillsValues.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {allSkillsValues?.map((skill, index) => (
                        <div
                          className="px-3 py-1 bg-expurple-800 text-golden w-fit rounded-md text-sm flex items-center justify-center gap-4"
                          key={uuidv4()}
                        >
                          <h1>{skill}</h1>
                          <div
                            className="cursor-pointer"
                            onClick={() => removeInterestHandler(index)}
                          >
                            <CrossIcon width="10" height="10" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Salary
          </h1>

          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div>
              <Controller
                name="minSalary"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Minimum salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Min Salary"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={minSalRange}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.minSalary?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="maxSalary"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Max salary is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Max Salary"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    options={maxSalRange}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.maxSalary?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="hideSalary"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExCheckBox
                  title="You can choose to hide salary range from candidates."
                  name="hideSalary"
                  labelStyle="text-expurple-800 text-base italic"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <h1 className="text-xl text-expurple-600 font-semibold text-start">
            Description & Responsibility
          </h1>

          <div>
            <Controller
              name="jobDescription"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Job Discription is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExTextarea
                  rows={8}
                  name="jobDescription"
                  value={value || ""}
                  title="Job Description"
                  maxLength={2600}
                  onChange={onChange}
                  placeholder="Enter here"
                  error={errors?.jobDescription?.message}
                />
              )}
            />
          </div>

          <div>
            <div className="w-fit ml-auto mr-0">
              {jobId ? (
                <button
                  disabled={!isDirty}
                  type="submit"
                  className="flex  items-center justify-center gap-1 px-8 py-2 rounded-md bg-expurple-800 text-base text-exwhite-100 text-center font-semibold"
                >
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  className="flex  items-center justify-center gap-1 pl-5 pr-3 py-2 rounded-md bg-expurple-800 text-base text-exwhite-100 text-center font-semibold"
                >
                  Post Job <RightArrowIcon />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateJob;
