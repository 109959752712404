import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import useCountryCodes from "../../../utils/dropdown-options/useCountryCodes";
import useLocations from "../../../utils/dropdown-options/useLocations";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { createReferApi } from "../../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  referCandidateApi,
  searchCandidatesApi,
} from "../../../slices/referSlice";
import * as constants from "../../../constant";

const CreateReferForm = ({ jobId, onClose = () => {} }) => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const [userDetails, setUserDetails] = useState(null);

  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();
  const { locationsArray, defaultLocation } = useLocations();

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      location: "",
      phoneNo: "",
      isFresher: false,
      companyName: "",
      designantion: "",
      experienceYears: null,
      experienceMonths: null,
      ctcInLacs: null,
      ctcInThousand: null,
      countryCode: {
        Id: 99,
        CountryName: "India",
        Iso3: "IND",
        PhoneCode: "91",
        PhoneCodeLabel: "IND +91",
      },
      uploadCV: "",
      tAndc: false,
    },
  });

  const { handleSubmit, control, formState, watch, reset, setValue } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    if (userDetails) {
      await dispatch(
        referCandidateApi({
          recruiterID: userId,
          candidateID: userDetails.id,
          jobID: jobId,
          status: "pending",
          referralType: userType,
        })
      ).then(unwrapResult);
      onClose();
    } else {
      try {
        const response = await dispatch(
          createReferApi({
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailId: formData.emailId,
            countryCode: parseInt(formData.countryCode.PhoneCode),
            phoneNo: parseInt(formData.phoneNo),
            yearsOfExperience: formData.experienceYears,
            designation: formData.designantion,
            authorType:
              userType === constants.COMPANY.toLowerCase() ||
              userType === constants.COMPANY_MEMBER.toLowerCase()
                ? "COMPANY"
                : "RECRUITER",
            authorId:
              userType === constants.COMPANY.toLowerCase()
                ? userId
                : userType === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : userType === constants.RECRUITER.toLowerCase()
                ? userId
                : "",
            location: formData.location.Id.toString(),
            socialInfo: {
              linkedin: {
                profileUrl: "https://linkedin.com/in/johndoe",
              },
            },
            organization: formData.companyName,
          })
        ).then(unwrapResult);

        await dispatch(
          referCandidateApi({
            recruiterID: userId,
            candidateID: response.data.data.userId,
            jobID: jobId,
            status: "pending",
            referralType: userType,
          })
        ).then(unwrapResult);
        onClose();
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  const { emailId, phoneNo } = watch();

  const searchCandidatehandler = async (isEmail) => {
    try {
      const response = await dispatch(
        searchCandidatesApi({
          mobile: !isEmail ? parseInt(phoneNo) : null,
          email: isEmail ? emailId : "",
          authorId:
            userType === constants.COMPANY.toLowerCase()
              ? userId
              : userType === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : userId,
        })
      ).then(unwrapResult);
      setUserDetails(response.data.data);
      const data = response.data.data;

      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("experienceYears", data.totalExperience);
      setValue("designantion", data.designation);
      setValue("companyName", data.organization);
      setValue("emailId", data.email);
      setValue("phoneNo", data.mobile.toString());
      setValue("location", defaultLocation(data.location));
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const clearFieldsHandler = () => {
    reset();
    setUserDetails(null);
  };

  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-xl text-expurple-800 font-semibold text-start">
          Candidate Details{" "}
          <span className="text-sm text-exgray-200 tracking-wide">
            Search / Create Candidate
          </span>
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 auto-rows-auto gap-2">
          <div className="flex items-center justify-between gap-2">
            <div className="min-w-[120px] self-start">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Country"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="PhoneCode"
                    optionLabel="PhoneCodeLabel"
                    options={modifiedCountryCodes}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.countryCode?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1 self-start">
              {/* <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Phone Number"
                    placeholder="Enter your phone number"
                    name="phoneNo"
                    type="number"
                    onChange={onChange}
                    value={value}
                    error={errors?.phoneNo?.message}
                  />
                )}
              /> */}

              <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor={"phoneNo"}
                      className={`text-lg font-normal text-expurple-800`}
                    >
                      Mobile No. *
                    </label>
                    <div className={`relative`}>
                      <input
                        type="number"
                        className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                        id="phoneNo"
                        onWheel={(e) => e.target.blur()}
                        readOnly={userDetails}
                        placeholder="Enter here"
                        autoComplete="off"
                        name="phoneNo"
                        onChange={onChange}
                        value={value}
                      />
                      <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                        <button
                          type="button"
                          disabled={!phoneNo}
                          onClick={() => searchCandidatehandler(false)}
                          className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    {errors?.phoneNo?.message && (
                      <p className="text-red-400">{errors?.phoneNo?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          <div>
            {/* <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Email ID"
                  placeholder="eg. abc@abc.com"
                  name="emailId"
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            /> */}

            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor={"emailId"}
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Email ID *
                  </label>
                  <div className={`relative`}>
                    <input
                      type="text"
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                      id="emailId"
                      readOnly={userDetails}
                      placeholder="eg. abc@abc.com"
                      autoComplete="off"
                      name="emailId"
                      onChange={onChange}
                      value={value}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                        disabled={!emailId}
                        onClick={() => searchCandidatehandler(true)}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {errors?.emailId?.message && (
                    <p className="text-red-400">{errors?.emailId?.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="First Name"
                  placeholder="First Name"
                  name="firstName"
                  readOnly={userDetails}
                  onChange={onChange}
                  value={value}
                  error={errors?.firstName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  readOnly={userDetails}
                  onChange={onChange}
                  value={value}
                  error={errors?.lastName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="designantion"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Designantion is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Designation"
                  placeholder="Enter Here"
                  name="designantion"
                  readOnly={userDetails}
                  onChange={onChange}
                  value={value}
                  error={errors?.designantion?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="experienceYears"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Experience is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Years of Experience"
                  placeholder="Ex. 5"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="experienceYears"
                  readOnly={userDetails}
                  onChange={onChange}
                  value={value || ""}
                  error={errors?.experienceYears?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Company Name"
                  placeholder="Enter Here"
                  name="companyName"
                  readOnly={userDetails}
                  onChange={onChange}
                  value={value}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Preffered Location *"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="">
          <div className="w-fit ml-auto mr-0 space-x-2">
            <button
              type="button"
              className="px-10 py-2 text-expurple-800 text-base font-semibold text-center bg-transparent rounded-md"
              onClick={clearFieldsHandler}
            >
              Clear
            </button>

            <button className="px-10 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md">
              Refer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateReferForm;
