import React from "react";
import { v4 as uuidv4 } from "uuid";
import UploadedUserExpCard from "./UploadedUserExpCard";

const UploadedExperienceSection = ({ userDetails }) => {
  console.log("userDetails :>> ", userDetails);
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-lg text-black text-semibold text-start">
          Experience
        </h1>
      </div>

      <div className="space-y-2">
        {userDetails?.userExperience?.map((expDetail) => (
          <UploadedUserExpCard expDetail={expDetail} key={uuidv4()} />
        ))}
      </div>
    </div>
  );
};

export default UploadedExperienceSection;
