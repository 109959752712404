import React from "react";

const BellIcon = ({ width = "28", height = "28", color = "#18191C" }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.14762 12.2499C6.14474 11.2168 6.34574 10.1932 6.73911 9.23791C7.13248 8.28257 7.71048 7.41425 8.44001 6.68268C9.16953 5.95111 10.0362 5.37069 10.9905 4.97465C11.9447 4.57861 12.9677 4.37476 14.0009 4.37476C15.034 4.37476 16.057 4.57861 17.0112 4.97465C17.9655 5.37069 18.8322 5.95111 19.5617 6.68268C20.2912 7.41425 20.8692 8.28257 21.2626 9.23791C21.656 10.1932 21.857 11.2168 21.8541 12.2499V12.2499C21.8541 16.1673 22.6737 18.4405 23.3955 19.6829C23.4733 19.8156 23.5146 19.9665 23.5155 20.1204C23.5163 20.2742 23.4765 20.4256 23.4002 20.5592C23.3238 20.6927 23.2136 20.8038 23.0807 20.8812C22.9477 20.9586 22.7967 20.9996 22.6428 20.9999H5.358C5.20413 20.9995 5.05309 20.9586 4.92011 20.8812C4.78713 20.8038 4.67692 20.6927 4.60059 20.5591C4.52426 20.4255 4.48451 20.2741 4.48536 20.1202C4.48621 19.9663 4.52763 19.8154 4.60543 19.6827C5.32766 18.4402 6.14762 16.1671 6.14762 12.2499H6.14762Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 21V21.875C10.5 22.8033 10.8687 23.6935 11.5251 24.3499C12.1815 25.0063 13.0717 25.375 14 25.375C14.9283 25.375 15.8185 25.0063 16.4749 24.3499C17.1313 23.6935 17.5 22.8033 17.5 21.875V21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0625 2.62378C21.843 3.74778 23.2782 5.34191 24.2098 7.23017"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.79004 7.23017C4.72165 5.34191 6.1569 3.74778 7.93737 2.62378"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
