import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { v4 as uuid4 } from "uuid";
import { getRecruiterDetailsApi } from "../../slices/recruiterSlice";
import RecruiterProfileCard from "./components/RecruiterProfileCard";
import RecruiterJobsList from "./components/RecruiterJobsList";
import SavedJobsList from "../candidate-applied-jobs/components/SavedJobsList";

const navigationButtons = [{ title: "Job List" }, { title: "Saved Jobs" }];

const RecruiterDashboard = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const recruiterSelector = useAppSelector(
    (state) => state?.recruiter?.recruiterDetails
  );

  const [toggle, setToggle] = useState(0);

  const { recruiterData, isLoading, error } = recruiterSelector;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getRecruiterDetailsApi({ recruiterId: userId })).then(
          unwrapResult
        );
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    fetchData();
  }, []);

  const toggleHandler = (index) => {
    setToggle(index);
  };

  if (error) {
    return <h1>Somthing went wrong</h1>;
  }

  return (
    recruiterData &&
    !isLoading && (
      <div className="w-[95%] mx-auto py-10 min-h-screenheight space-y-6">
        <RecruiterProfileCard />

        <div className="pb-2 border-b-2 border-b-exgray-100">
          {navigationButtons.map((navogationBtn, index) => (
            <button
              key={uuid4()}
              className={`py-2 px-8 text-base ${
                toggle === index
                  ? "bg-expurple-800 text-exwhite-100 rounded-md"
                  : "text-expurple-800"
              }`}
              onClick={() => toggleHandler(index)}
            >
              {navogationBtn.title}
            </button>
          ))}
        </div>

        {toggle === 0 && (
          <div>
            <RecruiterJobsList />
          </div>
        )}

        {toggle === 1 && <SavedJobsList />}
      </div>
    )
  );
};

export default RecruiterDashboard;
