import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import recruiterService from "../services/recruiter.service";

const initialState = {
  recruiterDetails: {
    recruiterData: null,
    isLoading: true,
    error: false,
  },
};

export const getRecruiterDetailsApi = createAsyncThunk(
  "recruiter/getRecruiterDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await recruiterService.getRecruiterDetailApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const recruiterSlice = createSlice({
  name: "recruiter",
  initialState,
  reducers: {
    removeRecruiterDetails: (state, action) => {
      state.recruiterDetails = {
        recruiterData: null,
        isLoading: true,
        error: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecruiterDetailsApi.pending, (state, action) => {
      state.recruiterDetails.isLoading = true;
      state.recruiterDetails.error = false;
    });

    builder.addCase(getRecruiterDetailsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.recruiterDetails.isLoading = false;
      state.recruiterDetails.error = false;
      state.recruiterDetails.recruiterData = response.userDetails;
    });

    builder.addCase(getRecruiterDetailsApi.rejected, (state, action) => {
      state.recruiterDetails.isLoading = false;
      state.recruiterDetails.error = true;
    });
  },
});

export const { removeRecruiterDetails } = recruiterSlice.actions;
export default recruiterSlice.reducer;
