import React from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constants from "../../../constant";
import { createMemberApi } from "../../../slices/companyMemberSlice";

const roles = [
  { Id: 1, Title: constants.ADMIN },
  { Id: 2, Title: constants.JOB_MANEGMENT },
  { Id: 3, Title: constants.CANDIDATE_MANEGMENT },
];

const CreateMember = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const form = useForm({
    defaultValues: {
      emailId: "",
      role: null,
    },
  });
  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const onSubmit = (formData) => {
    dispatch(
      createMemberApi({
        emailId: formData.emailId,
        designation: formData.role.Title,
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector.companyId,
        authorId: userId,
      })
    );

    onClose();
  };
  return (
    <div className="space-y-2">
      <h1 className="text-xl text-exgray-800 font-semibold border-b-2 border-b-exgray-100 pb-2">
        Create Member
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        {/* <div>
          <Controller
            name="mobileNo"
            control={control}
            rules={{
              required: {
                value: true,
                message: "First name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. 1212412"
                title="Mobile No"
                name="mobileNo"
                value={value || ""}
                onChange={onChange}
                error={errors?.mobileNo?.message}
              />
            )}
          />
        </div> */}

        {/* <div>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Last name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="EX. Nadella"
                title="Last Name"
                name="lastName"
                value={value || ""}
                onChange={onChange}
                error={errors?.lastName?.message}
              />
            )}
          />
        </div> */}

        <div>
          <Controller
            name="emailId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Please enter a valid email ID",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Official Email ID"
                placeholder="eg. abc@abc.com"
                name="emailId"
                onChange={onChange}
                value={value}
                error={errors?.emailId?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="role"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Role is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Member's Role"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={roles}
                dropDownHeight="40px"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                error={errors?.role?.message}
              />
            )}
          />
        </div>

        <div className="">
          <div className="w-fit ml-auto mr-0 pt-2">
            <button
              type="submit"
              className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMember;
