import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import referService from "../services/refer.service";
import jobService from "../services/job.service";

const initialState = {
  candidateList: { candidates: null, isloading: true, error: false },
  jobReferalList: {
    candidates: null,
    isloading: true,
    error: false,
  },
  jobAppliedCandidateList: {
    candidates: null,
    isloading: true,
    error: false,
  },
  refferedJobs: {
    jobs: null,
    isloading: true,
    error: false,
  },
};

export const getCandidatesApi = createAsyncThunk(
  "refer/getCandidatesApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await referService.getCandidates(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCandidatesByUrlApi = createAsyncThunk(
  "refer/getCandidatesByUrlApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    try {
      const response = await referService.getCandidatesByUrl(
        state.refer.candidateList.candidates.metadata.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const searchCandidatesApi = createAsyncThunk(
  "refer/searchCandidatesApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await referService.searchCandidate(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const referCandidateApi = createAsyncThunk(
  "refer/referCandidateApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await referService.referCandidate(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllReferralsApi = createAsyncThunk(
  "refer/getAllReferralsApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await referService.getAllReferrals(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextReferedJobsToCandidateApi = createAsyncThunk(
  "refer/getNextReferedJobsToCandidateApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await referService.getNextByUrl({
        nextUrl: state.refer.refferedJobs.jobs.metadata.nextResultURL,
        ..._data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateReferalStatusApi = createAsyncThunk(
  "refer/updateReferalStatusApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await referService.updateReferalStatus(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateApplicantStatusByMemberApi = createAsyncThunk(
  "job/updateApplicantStatusByMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateApplication(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const referSlice = createSlice({
  name: "refer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCandidatesApi.pending, (state, action) => {
      state.candidateList.isloading = true;
      state.candidateList.error = false;
    });

    builder.addCase(getCandidatesApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.candidateList.candidates = response;
      state.candidateList.isloading = false;
      state.candidateList.error = false;
    });

    builder.addCase(getCandidatesByUrlApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      console.log("response :>> ", response);
      state.candidateList.candidates.user =
        state.candidateList.candidates.user.concat(response.user);
      state.candidateList.candidates.metadata = response.metadata;
      // state.candidateList.candidates = response;
      state.candidateList.isloading = false;
      state.candidateList.error = false;
    });

    builder.addCase(getCandidatesApi.rejected, (state, action) => {
      state.candidateList.isloading = false;
      state.candidateList.error = true;
    });

    builder.addCase(getAllReferralsApi.pending, (state, action) => {
      state.jobReferalList.isloading = true;
      state.jobReferalList.error = false;
    });

    builder.addCase(getAllReferralsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      const candidateId = action.meta.arg.candidateId;
      const recruiterId = action.meta.arg.recruiterId;
      const refferedCandidate = action.meta.arg.refferedCandidate;

      if (candidateId) {
        state.refferedJobs.jobs = response;
        state.refferedJobs.isloading = false;
        state.refferedJobs.error = false;
      } else if (recruiterId && !refferedCandidate) {
        state.refferedJobs.jobs = response;
        state.refferedJobs.isloading = false;
        state.refferedJobs.error = false;
      } else if (recruiterId && refferedCandidate) {
        state.jobReferalList.candidates = response;
        state.jobReferalList.isloading = false;
        state.jobReferalList.error = false;
      } else if (refferedCandidate) {
        state.jobReferalList.candidates = response;
        state.jobReferalList.isloading = false;
        state.jobReferalList.error = false;
      }
    });

    builder.addCase(
      getNextReferedJobsToCandidateApi.fulfilled,
      (state, action) => {
        const response = action.payload.data.data;
        console.log("response :>> ", response);
        state.refferedJobs.jobs.referrals =
          state.refferedJobs.jobs.referrals.concat(response.referrals);
        state.refferedJobs.jobs.metadata = response.metadata;
      }
    );

    builder.addCase(getAllReferralsApi.rejected, (state, action) => {
      state.jobReferalList.isloading = false;
      state.jobReferalList.error = true;
    });

    builder.addCase(updateReferalStatusApi.fulfilled, (state, action) => {
      const payload = action.meta.arg;
      if (payload.candidateId && payload.status === "APPLIED") {
        const filteredReferdJobs = state.refferedJobs.jobs.referrals.filter(
          (refferdJob) => refferdJob.id !== payload.candidateId
        );

        state.refferedJobs.jobs.referrals = filteredReferdJobs;
      }
    });

    // builder.addCase(
    //   updateApplicantStatusByMemberApi.fulfilled,
    //   (state, action) => {
    //     const newArray = state.refferedJobs.jobs.referrals.map((ref) => {
    //       return {
    //         ...ref,
    //         applications: ref.applications.map((apps) => {
    //           if (apps.id === action.payload.data.data.id) {
    //             return { ...apps, status: action.payload.data.data.status };
    //           }
    //         }),
    //       };
    //     });

    //     console.log("newArray :>> ", newArray);

    //     state.refferedJobs.jobs.referrals = newArray;
    //   }
    // );

    builder.addCase(referCandidateApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;

      if (state?.jobReferalList?.candidates) {
        state.jobReferalList.candidates.referrals.push(response);
      }
    });
  },
});

export default referSlice.reducer;
