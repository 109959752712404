import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  toggleOtpHandler,
  verifyOtpApi,
  verifyRecruiterOTPApi,
} from "../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyCompanyOtpApi } from "../../slices/loginSlice";
import * as constants from "../../constant";
import { getCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { jobOpenedbeforeLoginHandler } from "../../slices/jobSlice";

let currentOTPIndex = 0;
const OtpField = ({ userType, onClose = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const otpDetails = useAppSelector((state) => state.login.ui);
  const jobSelector = useAppSelector(
    (state) => state.jobs.openedJobBeforelogin
  );

  const { requestId } = otpDetails || {};

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOtpTimerHandler = () => {
    setMinutes(1);
    setSeconds(59);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
  };

  const handleOnKeyDown = (e, index) => {
    currentOTPIndex = index;
    if (e.key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  const loginHandler = async () => {
    const joinedString = otp.join("");
    const otpNumber = Number(joinedString);
    if (!otp.includes("")) {
      if (userType === constants.USER.toLowerCase()) {
        const response = await dispatch(
          verifyOtpApi({
            otp: otpNumber,
            requestId: requestId,
          })
        ).then(unwrapResult);

        if (pathname === "/candidate/register") {
          dispatch(toggleOtpHandler());
        }

        if (response.data.data.accountExists) {
          if (response.data.data.session.who === constants.USER.toLowerCase()) {
            if (jobSelector.jobId) {
              try {
                await dispatch(
                  getCandidateDetailApi({
                    userId: response.data.data.session.userId,
                  })
                ).then(unwrapResult);
                navigate("/job-search");
              } catch (error) {
                dispatch(jobOpenedbeforeLoginHandler({ jobId: "" }));
                navigate("/job-search");
              }
            } else navigate("/candidate-profile");
          }
          if (
            response.data.data.session.who === constants.COMPANY.toLowerCase()
          ) {
            navigate("/company");
          }
          if (
            response.data.data.session.who ===
            constants.COMPANY_MEMBER.toLowerCase()
          ) {
            navigate("/company");
          }
          if (
            response.data.data.session.who === constants.RECRUITER.toLowerCase()
          ) {
            navigate("/recruiter");
          }
        } else navigate("/candidate/register");
      } else if (userType === constants.COMPANY.toLowerCase()) {
        const response = await dispatch(
          verifyCompanyOtpApi({ requestId: requestId, otp: otpNumber })
        ).then(unwrapResult);
        if (response.data.data.accountExists) {
          if (response.data.data.session.who === constants.USER.toLowerCase()) {
            navigate("/candidate");
          }
          if (
            response.data.data.session.who === constants.COMPANY.toLowerCase()
          ) {
            navigate("/company-admin");
          }
          if (
            response.data.data.session.who ===
            constants.COMPANY_MEMBER.toLowerCase()
          ) {
            navigate("/member");
          }
          if (response.data.data.session.who === "recruiter") {
            navigate("/recruiter");
          }
        } else {
          if (pathname === "/company") navigate("/company/register");
          else navigate("/company-profile");
        }
        onClose();
      } else if (userType === constants.RECRUITER.toLowerCase()) {
        const response = await dispatch(
          verifyRecruiterOTPApi({ requestId: requestId, otp: otpNumber })
        ).then(unwrapResult);

        if (response.data.data.accountExists) {
          if (response.data.data.session.who === constants.USER.toLowerCase()) {
            navigate("/candidate");
          }
          if (
            response.data.data.session.who === constants.COMPANY.toLowerCase()
          ) {
            navigate("/company");
          }
          if (
            response.data.data.session.who ===
            constants.COMPANY_MEMBER.toLowerCase()
          ) {
            navigate("/company");
          }
          if (response.data.data.session.who === "recruiter") {
            navigate("/recruiter-dashboard");
          }
        } else {
          navigate("/recruiter/register");
        }
        onClose();
      }
    }
  };

  return (
    <div className="w-full space-y-4">
      <h1 className="text-xl text-expurple-800 font-semibold tracking-wider text-center">
        Enter OTP
      </h1>
      <div className={"flex justify-center items-center space-x-5"}>
        {otp.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                ref={activeOTPIndex === index ? inputRef : null}
                type="number"
                className={
                  "w-12 h-12 border-2 rounded bg-expurple-100 outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-expurple-700 transition"
                }
                onChange={handleOnChange}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                value={otp[index]}
              />
              {/* {index === otp.length - 1 ? null : (
                  <span className={"w-2 py-0.5 bg-gray-400"} />
                )} */}
            </React.Fragment>
          );
        })}
      </div>

      <h1 className="text-center text-2xl text-expurple-700 font-semibold">
        {minutes < 10 ? `0${minutes}` : minutes} :{" "}
        {seconds < 10 ? `0${seconds}` : seconds}
      </h1>

      <h1 className="text-2xl text-expurple-700 font-medium text-center">
        Didn't recevie an OTP ?{" "}
        <button
          disabled={minutes > 0 || seconds > 0}
          className={`text-expurple-800 font-bold text-lg underline cursor-pointer ${
            minutes > 0 || seconds > 0 ? "opacity-45" : "opacity-100"
          }`}
          onClick={resendOtpTimerHandler}
        >
          Resend
        </button>
      </h1>

      <div className="">
        <div className="w-fit mx-auto">
          <button
            type="button"
            className="px-8 py-2 rounded-lg bg-expurple-800 text-center text-lg text-golden font-medium"
            onClick={loginHandler}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpField;
