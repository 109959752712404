import React, { useEffect, useState } from "react";

import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import { Link } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import {
  getAssignJobsToMemberApi,
  getRecommendedJobApi,
} from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../constant";

const MemberJobsList = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);
  const jobCards = useAppSelector((state) => state.jobs.jobCards);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getRecommendedJobApi({
            companyId:
              memberSelector.designation === constants.ADMIN
                ? memberSelector.companyId
                : // : userId,
                  memberSelector.companyId,
            jobStatus: "OPEN",
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    const fetchAssignedJobsData = async () => {
      try {
        const response = await dispatch(
          getAssignJobsToMemberApi({
            memberId: userId,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      fetchData();
    } else {
      fetchAssignedJobsData();
    }
  }, []);

  if (error) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center gap-2 w-fit ml-auto mr-0">
        {(memberSelector.designation === constants.ADMIN ||
          memberSelector.designation === constants.JOB_MANEGMENT) && (
          <Link
            to={"/create-job"}
            className="px-5 py-2 text-base text-exwhite-100 font-semibold flex items-center justify-center gap-2 bg-expurple-800 rounded-md"
          >
            <PlusCircleIcon width="25" height="25" color="white" /> Create Job
          </Link>
        )}
        {/* <button className="p-2">
          <FilterIcon />
        </button> */}
      </div>

      {!isLoading && (
        <div className="flex flex-wrap mx-auto">
          {jobCards?.jobs?.length > 0 &&
            jobCards.jobs.map((job) => (
              <div className="w-[33.3333333%]" key={uuid4()}>
                <Link to={`/job/${job.id}`}>
                  <JobCard job={job} />
                </Link>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MemberJobsList;
