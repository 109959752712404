import React from "react";

import LocationIcon from "../../assets/Icons/LocationIcon";
import DoubleChecksIcon from "../../assets/Icons/DoubleChecksIcon";

const CandidateReferCard = ({ candidate }) => {
  return (
    <div className="flex items-center justify-start p-4 bg-exwhite-100 gap-4 border-b border-b-exgray-100">
      <div className="h-28 w-28 bg-DefaultProfileImg bg-center bg-cover bg-no-repeat"></div>

      <div className="w-60 space-y-2">
        <h1 className="text-lg text-exgray-800 font-semibold leading-none">{`${candidate?.userDetails?.firstName} ${candidate?.userDetails?.lastName}`}</h1>
        <p className="text-base text-exgray-400">
          {candidate?.userDetails?.designation}
        </p>
        {/* <div className="flex items-center justify-start gap-2">
          <LocationIcon width="20" height="20" color="#5071C4" />
          <p className="text-expurple-700 text-base font-semibold">
            {candidate?.userDetails?.location}
          </p>
        </div> */}

        {/* <p className="text-base text-exgray-500 font-semibold">
          {candidate?.userDetails?.industry}
        </p> */}
      </div>

      <div className="w-fit ml-auto mr-4 flex items-center justify-center gap-2">
        {/* <DoubleChecksIcon /> */}
        <p className="text-exgray-200 text-base font-semibold">
          Action Pending...
        </p>
      </div>
    </div>
  );
};

export default CandidateReferCard;
