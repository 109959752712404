import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../components/Header/Navbar";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useEffect, useState } from "react";
import {
  getCategoriesApi,
  getCitiesApi,
  getCompanySizeRangeApi,
  getCompanyTypesApi,
  getCountriesApi,
  getDesignationsApi,
  getExperienceMaxApi,
  getExperienceMinApi,
  getFunctionsApi,
  getIndustriesApi,
  getJobTypeApi,
  getLanguagesApi,
  getSalaryMaxRangeApi,
  getSalaryRangeMinApi,
  getWorkplaceTypeApi,
} from "../../slices/appSlice";

const Main = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const [loading, setLoading] = useState(true);

  console.log("pathname :>> ", pathname);

  // useEffect(() => {
  //   if (userId && userType === "user") navigate("/candidate-profile");
  // }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const promises = [
          dispatch(getDesignationsApi(null)),
          dispatch(getCountriesApi(null)),
          dispatch(getIndustriesApi(null)),
          dispatch(getCategoriesApi(null)),
          dispatch(getFunctionsApi(null)),
          dispatch(getJobTypeApi(null)),
          dispatch(getWorkplaceTypeApi(null)),
          dispatch(getCitiesApi(null)),
          dispatch(getLanguagesApi(null)),
          dispatch(getSalaryMaxRangeApi(null)),
          dispatch(getSalaryRangeMinApi(null)),
          dispatch(getCompanyTypesApi(null)),
          dispatch(getCompanySizeRangeApi(null)),
          dispatch(getExperienceMaxApi(null)),
          dispatch(getExperienceMinApi(null)),
        ];
        await Promise.all(promises);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [dispatch]);
  return (
    <>
      <div className={`${!userId && pathname === "/" && "fixed w-full z-50"}`}>
        <Navbar />
      </div>
      {!loading && (
        <div className="min-h-screenheight w-full">
          <Outlet />
        </div>
      )}
    </>
  );
};

export default Main;
