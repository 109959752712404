import React, { useEffect, useState } from "react";
import CandidateCard from "../../../components/candidate-card/CandidateCard";
import { v4 as uuid4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getApplicantsApi } from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllReferralsApi } from "../../../slices/referSlice";
import * as constants from "../../../constant";

const JobApplicants = ({ jobId }) => {
  const dispatch = useAppDispatch();
  const jobSelector = useAppSelector((state) => state.jobs.jobApplicants);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [appliedCadidateViaRefer, setAppliedCadidateViaRefer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (
        loginSelector?.who === constants.COMPANY.toLowerCase() ||
        (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector?.designation === constants.ADMIN)
      ) {
        try {
          await dispatch(getApplicantsApi({ jobId })).then(unwrapResult);
        } catch (error) {
          console.log("error :>> ", error);
        }
      } else {
        try {
          const resposeRefer = await dispatch(
            getAllReferralsApi({
              jobId: jobId,
              // status: "applied",
              recruiterId: loginSelector?.userId,
            })
          ).then(unwrapResult);
          setAppliedCadidateViaRefer(resposeRefer.data.data);
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
    };

    fetchData();
  }, []);

  if (jobSelector.error) {
    return <h1>Somthing went wrong</h1>;
  }

  if (
    loginSelector?.who === constants.COMPANY.toLowerCase() ||
    (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector?.designation === constants.ADMIN &&
      !jobSelector.isLoading)
  ) {
    return (
      !jobSelector.isLoading && (
        <div className="space-y-2">
          {jobSelector?.applicants?.applications?.map((candidate) => (
            <CandidateCard
              candidate={candidate}
              candidateDetails={candidate.author}
              key={uuid4()}
            />
          ))}
        </div>
      )
    );
  } else {
    return (
      appliedCadidateViaRefer && (
        <div className="space-y-2">
          {appliedCadidateViaRefer?.referrals?.map((candidate) => (
            <CandidateCard
              candidate={candidate}
              setAppliedCadidateViaRefer={setAppliedCadidateViaRefer}
              appliedCadidateViaRefer={appliedCadidateViaRefer}
              candidateDetails={candidate.userDetails}
              key={uuid4()}
            />
          ))}
        </div>
      )
    );
  }
};

export default JobApplicants;
