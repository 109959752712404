import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../config/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { getMemberByIdApi } from "../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../components/basic-components/ExInput";
import ExDropdown from "../../components/basic-components/ExDropdown";
import useCountryCodes from "../../utils/dropdown-options/useCountryCodes";
import { registerCompanyMemberApi } from "../../slices/loginSlice";
import * as constants from "../../constant";

const MemberSignup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { memberId } = useParams();

  const { modifiedCountryCodes } = useCountryCodes();

  const [memberDetails, setMemberDetails] = useState(null);
  const [error, setError] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      countryCode: constants.IND_COUNTRYCODE,
      mobileNo: "",
      designantion: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getMemberByIdApi({ memberId: memberId })
        ).then(unwrapResult);
        const data = response.data.data;
        setMemberDetails(data);
        reset({
          emailId: data.emailId,
          designantion: data.designation,
        });
      } catch (error) {
        setError(true);
      }
    };

    if (memberId) {
      fetchData();
    }
  }, []);

  const onSubmit = async (formData) => {
    try {
      await dispatch(
        registerCompanyMemberApi({
          // requestId: response.data.data.requestId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailId: formData.emailId,
          countryCode: parseInt(formData.countryCode.PhoneCode),
          userId: memberId,
          companyId: memberDetails.companyId,
          isEmailVerified: true,
          mobileNo: parseInt(formData.mobileNo),
          designation: formData.designantion,
        })
      ).then(unwrapResult);
      navigate("/member");
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div className="min-h-screenheight bg-loginBackground bg-cover bg-center bg-no-repeat flex items-center justify-center">
      <div className="flex-1"></div>
      <div className="flex-1">
        <h1 className="text-4xl text-expurple-800 font-semibold text-center py-4">
          Member Signup
        </h1>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[80%] mx-auto space-y-3"
        >
          <div className="flex items-center justify-between gap-2">
            <div className="w-full self-start">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="First Name"
                    placeholder="First Name"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    error={errors?.firstName?.message}
                  />
                )}
              />
            </div>
            <div className="w-full self-start">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Last Name is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    error={errors?.lastName?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Email ID"
                  placeholder="eg. abc@abc.com"
                  name="emailId"
                  readOnly={true}
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="min-w-[120px] self-start">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Country"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="PhoneCode"
                    optionLabel="PhoneCodeLabel"
                    options={modifiedCountryCodes}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.countryCode?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1 self-start">
              <Controller
                name="mobileNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Phone Number"
                    placeholder="Enter your phone number"
                    name="mobileNo"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={onChange}
                    value={value}
                    error={errors?.mobileNo?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="designantion"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Designantion is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Role"
                  placeholder="Ex. Admin"
                  name="designantion"
                  onChange={onChange}
                  readOnly={true}
                  value={value}
                  error={errors?.designantion?.message}
                />
              )}
            />
          </div>

          <div className="">
            <div className="w-fit mx-auto">
              <button
                type="submit"
                className={`py-2 px-10 bg-expurple-800 text-lg text-exwhite-100 text-center font-semibold rounded-xl tracking-wider`}
              >
                SignUp
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MemberSignup;
