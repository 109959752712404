import React, { useEffect } from "react";

import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import { Link } from "react-router-dom";
import JobCard from "../../job-search/components/JobCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import {
  getCreatedJobsApi,
  getNextCreatedCompanyJobsApi,
} from "../../../slices/companySlice";
import InfiniteScroll from "react-infinite-scroll-component";

const CompanyJobsDashboard = () => {
  const dispatch = useAppDispatch();
  const companySelector = useAppSelector((state) => state.company);
  const stateSelector = useAppSelector((state) => state);
  const { createdJobs, companyDetails } = companySelector;
  const { jobCards, isLoading, error } = createdJobs;

  useEffect(() => {
    dispatch(
      getCreatedJobsApi({
        companyId: companyDetails.id,
        jobStatus: "OPEN",
        size: "2",
      })
    );
  }, []);

  const getMoreDataHandler = () => {
    console.log("getMore");
    dispatch(getNextCreatedCompanyJobsApi());
  };

  console.log(
    "jobCards?.metadata?.nextResultURL :>> ",
    jobCards?.metadata?.nextResultURL
  );

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center gap-2 w-fit ml-auto mr-0">
        <Link
          to={"/create-job"}
          className="px-5 py-2 text-base text-exwhite-100 font-semibold flex items-center justify-center gap-2 bg-expurple-800 rounded-md"
        >
          <PlusCircleIcon width="25" height="25" color="white" /> Create Job
        </Link>
        {/* <button className="p-2">
          <FilterIcon />
        </button> */}
      </div>

      {error ? (
        <h1>somthing went wrong</h1>
      ) : (
        !isLoading && (
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={jobCards?.jobs?.length ? jobCards?.jobs?.length : 0}
            next={() => getMoreDataHandler()}
            hasMore={jobCards?.metadata?.nextResultURL}
            // hasMore={true}
            // height={"calc(100vh - 155px)"}
            loader={""}
          >
            <div className="flex flex-wrap mx-auto">
              {jobCards?.jobs?.length > 0 &&
                jobCards.jobs.map((job) => (
                  <div className="w-[33.3333333%]" key={uuid4()}>
                    <Link to={`/job/${job.id}`}>
                      <JobCard job={job} />
                    </Link>
                  </div>
                ))}
            </div>
          </InfiniteScroll>
        )
      )}
    </div>
  );
};

export default CompanyJobsDashboard;
