import React, { useMemo, useRef } from "react";
import BellIcon from "../../../assets/Icons/BellIcon";
import { Link } from "react-router-dom";
import UploadIcon from "../../../assets/Icons/UploadIcon";
import { useAppSelector } from "../../../config/hooks";
import * as constatnts from "../../../constant";
import useLocations from "../../../utils/dropdown-options/useLocations";

const Profile = () => {
  const imageInputRef = useRef(null);
  const token = useAppSelector((state) => state?.login?.session?.token);
  const { defaultLocation } = useLocations();

  const userDetails = useAppSelector(
    (state) => state.candidateProfile.userDetails
  );

  const defaulLoc = useMemo(() => {
    if (userDetails.location) return defaultLocation(userDetails.location);
    else return "";
  }, [defaultLocation, userDetails.location]);

  const handleClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const onChangeHandler = (e) => {
    const file = e.target.files;
  };

  return (
    <div className="flex items-center justify-between gap-4 py-4">
      {userDetails?.profilePicture ? (
        <div className="h-40 w-40 rounded-lg flex items-center justify-center overflow-hidden">
          <img
            src={`${constatnts.baseUrl}/files/${userDetails?.profilePicture}?t=${token}`}
            alt="profile"
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        <div className="h-40 w-40 bg-DefaultProfileImg bg-center bg-no-repeat bg-cover rounded-md ">
          {/* <div className="text-center w-[90%] mx-auto">
            <div className="cursor-pointer w-fit mx-auto" onClick={handleClick}>
              <div>
                <UploadIcon height="35" width="35" />
              </div>
              <input
                ref={imageInputRef}
                type="file"
                name="image"
                multiple
                accept="image/*"
                onChange={onChangeHandler}
                style={{ display: "none" }}
              />
            </div>
            <h2 className="text-base text-exgray-200 font-semibold text-center">
              Browse Photo
            </h2>
          </div> */}
        </div>
      )}
      <div className="flex-1">
        <h1 className="text-2xl text-expurple-800 font-semibold">
          {`${userDetails?.firstName} ${userDetails?.lastName}`}
        </h1>
        <h2 className="text-base text-expurple-800 font-semibold tracking-wide">
          {`${
            userDetails?.designation
          } |  ${`${userDetails?.totalExperience} Yrs Exp`} | ${
            userDetails?.organization
          }`}
        </h2>
        <p className="text-base text-exgray-600 tracking-wide">
          {defaulLoc?.Name}
        </p>
      </div>
      <div className="self-start flex items-center justify-center gap-8">
        <div className="cursor-pointer">
          <BellIcon />
        </div>
        <Link to={"/candidate-profile"}>
          <button className="text-lg text-expurple-700 font-semibold tracking-wide">
            Edit Profile
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Profile;
