import React, { useEffect, useState } from "react";
import { v4 as uuid4 } from "uuid";

import SearchBar from "./components/SearchBar";
import JobCard from "./components/JobCard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getAppliedJobApi,
  getNextJobApi,
  getRecommendedJobApi,
  jobOpenedbeforeLoginHandler,
} from "../../slices/jobSlice";
import Popup from "../../components/popup/Popup";
import JobDetail from "./components/JobDetail";
import InfiniteScroll from "react-infinite-scroll-component";
import { unwrapResult } from "@reduxjs/toolkit";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";
// import { getCandidatesByUrlApi } from "../../slices/referSlice";

const JobSearch = () => {
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const jobData = useAppSelector((state) => state.jobs);
  const { jobCards, isLoading, error } = jobData;

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        dispatch(getAppliedJobApi()).then(unwrapResult);
      }

      if (jobData.jobSearchFields.keyword || jobData.jobSearchFields.location) {
        dispatch(
          getRecommendedJobApi({
            status: "OPEN",
            s: jobData.jobSearchFields.keyword,
            location: jobData.jobSearchFields.location
              ? [jobData.jobSearchFields.location]
              : null,
          })
        );
      } else {
        await dispatch(
          getRecommendedJobApi({
            status: "OPEN",
            notAuthorId: userId,
          })
        ).then(unwrapResult);
      }

      if (jobData.openedJobBeforelogin.jobId) {
        togglePopupHandler(jobData.openedJobBeforelogin.jobId);
      }
    };

    fetchData();

    return () => {
      if (jobData.openedJobBeforelogin.jobId) {
        dispatch(jobOpenedbeforeLoginHandler({ jobId: "" }));
      }
    };

    // dispatch(getRecommendedJobApi({ notAuthorId: userId, status: "OPEN" }));
  }, []);

  const togglePopupHandler = (jobId) => {
    setTogglePopup(!togglePopup);
    setSelectedJobId(jobId);
  };

  const closePopupHandler = () => {
    setTogglePopup(false);
    setSelectedJobId("");
  };

  const getMoreDataHandler = () => {
    dispatch(getNextJobApi());
  };

  if (error) {
    <h1>Something went wrong</h1>;
  } else {
    if ((!isLoading && !jobCards?.jobs) || jobCards?.jobs?.length === 0) {
      return (
        <div>
          <div className="flex items-center justify-center pt-20">
            <NoDataFoundIcon />
          </div>
          <div>
            <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
              No Data Found
            </h1>
            <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
              Find new opportunities and manage your job search progress here.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="min-h-screenheight bg-expurple-200">
        <div className="w-[95%] mx-auto py-10 space-y-2">
          <SearchBar />
          {!isLoading && (
            <InfiniteScroll
              className="no-scrollbar"
              dataLength={jobCards?.jobs?.length ? jobCards?.jobs?.length : 0}
              next={() => getMoreDataHandler()}
              hasMore={jobCards?.metadata?.nextResultURL}
              // height={"calc(100vh - 155px)"}
              loader={""}
            >
              <div className="flex flex-wrap mx-auto">
                {jobCards?.jobs?.length > 0 &&
                  jobCards.jobs.map((job) => (
                    <div className="w-[33.3333333%]" key={uuid4()}>
                      <div onClick={() => togglePopupHandler(job.id)}>
                        <JobCard job={job} />
                      </div>
                      <Popup
                        onClose={closePopupHandler}
                        key={uuid4()}
                        visible={job.id === selectedJobId}
                      >
                        {job.id === selectedJobId && (
                          <div className="bg-expurple-200 rounded-xl w-[75%] h-[90%] overflow-y-auto no-scrollbar">
                            <JobDetail
                              jobDetails={job}
                              closePopupHandler={closePopupHandler}
                              applied={false}
                            />
                          </div>
                        )}
                      </Popup>
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  }
};

export default JobSearch;
