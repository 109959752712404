import React from "react";
import { useAppSelector } from "../../../config/hooks";
import BellIcon from "../../../assets/Icons/BellIcon";
import StandardEnvolpIcon from "../../../assets/Icons/StandardEnvolpIcon";
import StandardPhoneIcon from "../../../assets/Icons/StandardPhoneIcon";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../constant";

const MemberProfileCard = () => {
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const memberSelector = useAppSelector((state) => state.member);

  const { memberDetails } = memberSelector;

  const navigationHandler = () => {
    navigate("/bulk-upload");
  };

  return (
    <div
      className="p-4 bg-exwhite-100 rounded-md flex items-center justify-between gap-2"
      style={{
        boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.7)",
      }}
    >
      <div className="h-28 w-28 bg-samplelogo bg-cover bg-center bg-no-repeat"></div>

      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <h1 className="text-xl text-expurple-600 font-semibold">
            {`${memberDetails?.firstName} ${memberDetails?.lastName}`}
          </h1>
          <div className="mr-10">
            <BellIcon />
          </div>
        </div>

        <div>
          <p className="text-base text-expurple-800 font-semibold">
            {memberDetails?.designation}
          </p>
        </div>

        <div className="flex items-center justify-start gap-10">
          <p className="flex items-center justify-start gap-1 text-base text-expurple-800 font-semibold">
            <StandardEnvolpIcon width="20" height="20" color="#696969" />
            {memberDetails?.emailId}
          </p>
          <p className="text-base text-expurple-800 flex items-center justify-start gap-2 leading-none font-semibold">
            <StandardPhoneIcon width="20" height="20" color="#696969" />
            {`${
              memberDetails?.mobileNo === 0
                ? "----------"
                : memberDetails?.mobileNo
            }`}
          </p>

          {userType === constants.COMPANY_MEMBER.toLowerCase() &&
            (memberDetails?.designation === constants.CANDIDATE_MANEGMENT ||
              memberDetails?.designation === constants.ADMIN) && (
              <div className="w-fit ml-auto mr-6">
                <button
                  className="px-6 py-2 text-base text-expurple-800 border-2 border-expurple-800 rounded-md font-semibold w-fit  hover:bg-expurple-800 hover:text-exwhite-100"
                  onClick={navigationHandler}
                >
                  Candidate Pool
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default MemberProfileCard;
