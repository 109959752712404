import React, { useEffect, useRef, useState } from "react";
import UploadIcon from "../../assets/Icons/UploadIcon";
import uploadContent from "../../utils/upload-content/uploadContent";
import { v4 as uuidV4 } from "uuid";
import FileIcon from "../../assets/Icons/FileIcon";
import CrossIcon from "../../assets/Icons/CrossIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getCvDetailsApi } from "../../slices/aiSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../constant";
import ErrorIcon from "../../assets/Icons/ErrorIcon";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import { bulkUploadRegisterApi } from "../../slices/loginSlice";
import Popup from "../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
import {
  addBulkUserEducationApi,
  addBulkUserExperienceApi,
  createBulkUploadAdditionalDetailsApi,
  updateBulkUserProfileApi,
} from "../../slices/candidateProfileSlice";
import moment from "moment";

const BundleUpload = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resumes, setResumes] = useState([]);
  const [resumesCopy, setResumesCopy] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getCreatedResponse, setGetCreatedResponse] = useState(false);
  const [successCount, setSuccessCount] = useState(0);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const imageInputRef = useRef(null);

  useEffect(() => {
    let newArray = [];
    if (resumes.length > 0 && resumesCopy.length > 0) {
      for (let index = 0; index < resumes.length; index++) {
        let foundMatch = false;

        for (let j = 0; j < resumesCopy.length; j++) {
          if (resumes.length - 1 === j) setIsLoading(false);

          if (
            resumes[index].orginalFileName === resumesCopy[j].orginalFileName
          ) {
            newArray.push({
              orginalFileName: resumesCopy[j].orginalFileName,
              isUploaded: resumesCopy[j].isUploaded,
              isLoading: false,
              data: resumesCopy[j].data,
              url: resumesCopy[j].url,
              contentType: resumesCopy[j].contentType,
              size: resumesCopy[j].size,
              errorType: resumesCopy[j].errorType,
            });
            foundMatch = true; // Mark as found and break
            break;
          }
        }

        // If no match was found, push the original resume
        if (!foundMatch) {
          newArray.push(resumes[index]);
        }
      }

      setResumes(newArray);
    }
  }, [resumesCopy]);

  useEffect(() => {
    if (resumes.length === 0) {
      setGetCreatedResponse(false);
    }
  }, [resumes]);

  const handleClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const removeResumeHanlder = (index) => {
    const filteredArray = resumes.filter((_data, i) => i !== index);
    setResumes(filteredArray);
    setResumesCopy(filteredArray);
  };

  const removeAllHandler = () => {
    setResumes([]);
    setResumesCopy([]);
    setGetCreatedResponse(false);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const resumeDetailsHandler = async (uploadContent) => {
    if (uploadContent[0].isUploaded) {
      const formData = new FormData();
      formData.append(
        "resumes",
        `${constants.baseUrl}/files/${uploadContent[0].url}?t=${loginSelector.token}`
      );

      try {
        const response = await dispatch(getCvDetailsApi(formData)).then(
          unwrapResult
        );

        console.log("response.data :>> ", response.data);

        setResumesCopy((prevResumes) => [
          ...prevResumes,
          {
            orginalFileName: uploadContent[0].orginalFileName,
            isUploaded:
              response.data.email && response.data.phoneNo
                ? uploadContent[0].isUploaded
                : false,
            isLoading: false,
            data: response.data,
            url: uploadContent[0].url,
            contentType: uploadContent[0].contentType,
            size: uploadContent[0].size,
            errorType:
              response.data.email && response.data.phoneNo
                ? ""
                : "Email and phone number are missing",
            // skills: response.data.SkillsInfo,
            // experience: [],
            // education: response.data.educationHistory,
            // interest: [],
            // industry: [],
            // languages: [],
          },
        ]);
      } catch (error) {
        setResumesCopy((prevResumes) => [
          ...prevResumes,
          {
            orginalFileName: uploadContent[0].orginalFileName,
            isUploaded: false,
            isLoading: false,
            data: null,
            url: "",
            contentType: "",
            size: 0,
            errorType: "Something went wrong",
          },
        ]);
      }
    } else {
      setResumesCopy((prevResumes) => [
        ...prevResumes,
        {
          orginalFileName: uploadContent[0].orginalFileName,
          isUploaded: uploadContent[0].isUploaded,
          isLoading: uploadContent[0].isLoading,
          data: null,
          url: "",
          contentType: "",
          size: 0,
          errorType: "Pelase try again",
        },
      ]);
    }
  };

  const onChangeHandler = (e) => {
    setGetCreatedResponse(false);
    setIsLoading(true);
    setResumes([]);
    if (e.target.files && e.target.files.length > 0) {
      const newArray = Array.from(e.target.files).map((fileData) => ({
        orginalFileName: fileData.name,
        isUploaded: true,
        isLoading: true,
        data: null,
        errorType: "",
        // experience: [],
        // education: [],
        // skills: [],
        // interest: [],
        // industry: [],
        // languages: [],
      }));

      setResumes(newArray);

      Array.from(e.target.files).forEach((file, index) => {
        uploadContent.bulkUploadFiles(
          loginSelector?.userId,
          file,
          (response) => {
            // setResumes((prevResumes) => [
            //   ...prevResumes,
            //   {
            //     orginalFileName: response[0].orginalFileName,
            //     isUploaded: response[0].isUploaded,
            //     isLoading: response[0].isLoading,
            //     data: null,
            //   },
            // ]);
            resumeDetailsHandler(response);
          }
        );
      });
    }
  };

  const createUser = async () => {
    if (resumes.length > 0 && !getCreatedResponse) {
      const newArray = resumes.map((resumeData) => {
        if (resumeData.isUploaded) {
          const userProfileData = resumeData.data;
          return {
            firstName: userProfileData.firstName,
            lastName: userProfileData.lastName,
            emailId: userProfileData?.email ? userProfileData?.email : "",
            countryCode: userProfileData?.countryCode
              ? parseInt(userProfileData?.countryCode)
              : 91,
            phoneNo: userProfileData?.phoneNo
              ? parseInt(userProfileData?.phoneNo)
              : 0,
            yearsOfExperience: userProfileData?.totalExperience
              ? userProfileData?.totalExperience.toString()
              : userProfileData?.yearOfExperience
              ? userProfileData?.yearOfExperience.toString()
              : "",
            designation: userProfileData?.designation
              ? userProfileData?.designation
              : "",
            location: userProfileData?.location
              ? userProfileData?.location
              : "",
            industry: userProfileData?.industry
              ? [userProfileData?.industry]
              : null,
            socialInfo: {
              linkedin: {
                profileUrl: userProfileData?.socialInfo[0]
                  ? userProfileData?.socialInfo[0]
                  : "",
              },
            },
            organization: userProfileData?.organization
              ? userProfileData?.organization
              : "",
            orginalFileName: resumeData?.orginalFileName
              ? resumeData.orginalFileName
              : "",
            resume: {
              name: resumeData?.orginalFileName
                ? resumeData?.orginalFileName
                : "",
              url: resumeData?.url ? resumeData?.url : "",
              contentType: resumeData.contentType ? resumeData.contentType : "",
              size: resumeData?.size ? resumeData?.size : 0,
            },
            authorType:
              userType === constants.COMPANY.toLowerCase() ||
              userType === constants.COMPANY_MEMBER.toLowerCase()
                ? "COMPANY"
                : "RECRUITER",
            authorId:
              userType === constants.COMPANY.toLowerCase()
                ? userId
                : userType === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : userType === constants.RECRUITER.toLowerCase()
                ? userId
                : "",
            skills: userProfileData.SkillsInfo,
            experience: userProfileData.professionalHistory,
            education: userProfileData.educationHistory,
            // interest: [],
            // languages: [],
          };
        } else {
          return {};
        }
      });

      const filteredArray = newArray.filter(
        (obj) => Object.keys(obj).length !== 0
      );

      console.log("filteredArray :>> ", filteredArray);

      try {
        const response = await dispatch(
          bulkUploadRegisterApi(filteredArray)
        ).then(unwrapResult);

        setGetCreatedResponse(true);

        if (response.data.conflicts && response.data.conflicts.length > 0) {
          const newArray = resumes.map((cvData) => {
            const conflict = response.data.conflicts.find(
              (conflict) => conflict.orginalFileName === cvData.orginalFileName
            );
            if (conflict) {
              return {
                ...cvData,
                isUploaded: false,
                errorType: "Already Exists",
              };
            } else {
              return cvData;
            }
          });

          setResumes(newArray);
        }

        if (
          response.data.message === "Some users already exist" &&
          response.data.status === "error" &&
          response?.data?.success &&
          response?.data?.success.length > 0
        ) {
          setSuccessCount(response.data.success.length);
        } else if (
          response.data.message === "All users registered successfully" &&
          response.data.status === "success"
        ) {
          navigate("/success");
          response.data.data.forEach((userData) => {
            const resumeDetail = newArray.find(
              (fileData) =>
                fileData.orginalFileName === userData.orginalFileName
            );
            console.log("resumeDetail :>> ", resumeDetail);

            if (
              resumeDetail?.experience &&
              resumeDetail?.experience?.length > 0
            ) {
              resumeDetail?.experience.forEach((exp) => {
                const startDateMonth = exp.startDate
                  ? moment(exp.startDate).format("MMMM")
                  : "";
                const startDateYear = exp.startDate
                  ? moment(exp.startDate).format("YYYY")
                  : "";
                const endDateMonth = exp.endDate
                  ? exp.endDate === "Present" || exp.endDate === "till date"
                    ? moment().format("MMMM")
                    : moment(exp.endDate).format("MMMM")
                  : "";
                const endDateYear = exp.endDate
                  ? exp.endDate === "Present" || exp.endDate === "till date"
                    ? moment().format("YYYY")
                    : moment(exp.endDate).format("YYYY")
                  : "";

                dispatch(
                  addBulkUserExperienceApi({
                    userId: userData.userId,
                    organization: exp?.organization ? exp?.organization : "",
                    organizationId: "",
                    userExperienceDetails: [
                      {
                        location: exp.location ? exp.location : "",
                        locationId: "",
                        locationType: "",
                        // employmentType: employmentType.toString(),
                        title: exp.designation ? exp.designation : "",
                        description: exp.description ? exp.description : "",
                        startDate: {
                          month: startDateMonth,
                          year: startDateYear,
                        },
                        endDate: {
                          month: endDateMonth,
                          year: endDateYear,
                        },
                        isCurrentRole:
                          exp.endDate === "Present" ||
                          exp.endDate === "till date"
                            ? true
                            : false,
                        skills: ["Java", "Software"],
                      },
                    ],
                  })
                );
              });
            }

            if (
              resumeDetail?.education &&
              resumeDetail?.education?.length > 0
            ) {
              resumeDetail?.education.forEach((edu) => {
                const startDateMonth = edu.startDate
                  ? moment(edu.startDate).format("MMMM")
                  : "";
                const startDateYear = edu.startDate
                  ? moment(edu.startDate).format("YYYY")
                  : "";
                const endDateMonth = edu.endDate
                  ? moment(edu.endDate).format("MMMM")
                  : "";
                const endDateYear = edu.endDate
                  ? moment(edu.endDate).format("YYYY")
                  : "";

                dispatch(
                  addBulkUserEducationApi({
                    userId: userData.userId,
                    institute: edu.institute ? edu.institute : "",
                    organizationId: "",
                    degree: edu.degree ? edu.degree : "",
                    description: edu.description ? edu.description : "",
                    grade: edu.grade ? edu.grade : "",
                    fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
                    activitiesAndSocieties: [],
                    startDate: {
                      month: startDateMonth,
                      year: startDateYear,
                    },
                    endDate: {
                      month: endDateMonth,
                      year: endDateYear,
                    },
                  })
                );
              });
            }

            if (resumeDetail.skills && resumeDetail.skills.length > 0) {
              dispatch(
                updateBulkUserProfileApi({
                  userId: userData.userId,
                  skills: resumeDetail.skills,
                })
              );
            }

            if (resumeDetail.industry && resumeDetail.industry) {
              dispatch(
                updateBulkUserProfileApi({
                  userId: userData.userId,
                  industry: resumeDetail.industry,
                })
              );
            }

            if (resumeDetail?.socialInfo?.linkedin?.profileUrl) {
              dispatch(
                updateBulkUserProfileApi({
                  userId: userData.userId,
                  socialInfo: {
                    Twitter: {
                      type: "twitter",
                      profileURL:
                        resumeDetail?.socialInfo?.linkedin?.profileUrl,
                    },
                  },
                })
              );
            }
          });
        }
      } catch (error) {
        if (error) {
          setError(true);
        }
      }
    } else {
      removeAllHandler();
    }
  };

  return (
    <div>
      <div className="w-[70%] mx-auto py-5">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-expurple-800 text-start font-semibold">
            Create Candidate profile
          </h1>
          <div className="flex items-center justify-center gap-2">
            <button
              className="text-base text-expurple-800 font-semibold py-2 px-10 rounded-md bg-transparent text-center"
              onClick={() => navigateHandler("/job-seekers")}
            >
              Back
            </button>
            <button
              className={`text-base text-exwhite-100 ${
                isLoading ? "bg-exgray-200" : "bg-expurple-800"
              } font-semibold py-2 px-10 rounded-md  text-center`}
              onClick={createUser}
              disabled={isLoading}
            >
              Create
            </button>
          </div>
        </div>
        {resumes.length === 0 && (
          <div>
            <h1 className="text-base text-exgray-200 font-semibold text-start">
              Upload Candidate Resumes
            </h1>
            <div className="h-56 w-full flex items-center justify-center rounded-sm border-2 border-dashed border-exgray-100 bg-exgray-100/10">
              <div className="text-center w-[90%] mx-auto">
                <div
                  className="cursor-pointer w-fit mx-auto"
                  onClick={handleClick}
                >
                  <div>
                    <UploadIcon height="40" width="40" />
                  </div>
                  <input
                    ref={imageInputRef}
                    type="file"
                    name="files"
                    multiple
                    accept=".pdf, .doc, .docx"
                    // accept=".pdf, .doc, .docx, .xls, .xlsx"
                    // accept="image/*"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                  />
                </div>
                <h2 className="text-xl text-exgray-400 font-semibold text-center">
                  Browse Photo
                </h2>
                <p className="text-base text-exgray-200 font-normal text-center max-w-xl mx-auto">
                  Upload multiple resumes in bulk to automatically create
                  candidate profiles. Accepted formats include PDF, DOCX, and
                  ZIP files.
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-between gap-2 my-2">
          <h1 className="text-base text-exgray-200 text-start font-semibold">{`${
            !resumes ? "0" : resumes.length
          } Uploaded Files`}</h1>

          {getCreatedResponse && successCount !== 0 && (
            <h1 className="text-center">
              {` ${successCount} out of ${resumes.length} files were created successfully.`}
            </h1>
          )}

          {resumes && resumes?.length > 0 && (
            <button
              className="text-base text-expurple-800 text-start font-semibold"
              onClick={removeAllHandler}
            >
              Remove All
            </button>
          )}
        </div>

        {resumes && resumes.length > 0 && (
          <div className="space-y-2 mt-2">
            {resumes.map((resume, index) => (
              <div
                className="flex items-center gap-2 justify-center"
                key={uuidV4()}
              >
                <div
                  className={`w-full p-2 ${
                    resume.isUploaded ? "bg-expurple-100" : "bg-red-200/70"
                  } rounded-md flex items-center justify-between gap-2`}
                >
                  {resume.isUploaded ? (
                    <FileIcon width="32" height="32" />
                  ) : (
                    <div className="">
                      <ErrorIcon />
                    </div>
                  )}
                  <h1 className="flex-1">{resume.orginalFileName}</h1>

                  <h1 className="mr-4 text-[#E05151] font-semibold">
                    {resume.errorType}
                  </h1>

                  <div
                    className="mr-2 cursor-pointer"
                    onClick={() => removeResumeHanlder(index)}
                  >
                    {resume.isLoading ? (
                      <LoadingIcon />
                    ) : (
                      <CrossIcon
                        width="15"
                        height="15"
                        color={resume.isUploaded ? "#A7AFC6" : "#E05151"}
                      />
                    )}
                  </div>
                </div>
                {/* {resume.isLoading && <LoadingIcon />} */}
              </div>
            ))}
          </div>
        )}
      </div>
      <Popup onClose={() => setError(false)} visible={error}>
        <div className="w-[40%] bg-expurple-200 p-10 rounded-xl space-y-2">
          <div className="w-fit mx-auto">
            <ErrorIcon width="50" height="50" />
          </div>
          <h1 className="text-center text-xl text-expurple-600 font-semibold">
            Somthing went wrong
          </h1>
          <p className="text-lg text-exgray-200 text-center">
            Please try again
          </p>
        </div>
      </Popup>
    </div>
  );
};

export default BundleUpload;

//http://api.dev.linkcxo.com/files/files/exotalent/7233341726561099776/bulkUpload/7238395442923917312.pdf?t=IaGilgUOLofOzbRuRKM5jUI70S1oORxWRGUMNPW8yYDLGbNmHfZOOodWZFGk55j7KsBOVr1jZgQuZPfN5NZniRtGoanyzNlgb7GDEc0nIJFtadtexBEptRJWE4vhWVDt

//https://api.dev.exotalent.in/files/files/exotalent/7233341726561099776/bulkUpload/7240140262583390208.docx?t=FsSkxnox7mnSiXedNc0KKSaMgNGjszgew2BID77toZjhbOV71utzJBJvDagCWcGu4TXQhVKIzRphdl0De8FzZav22LVxZBlCa08XNSgzpP4Zo3BMW17X8SpitrfhN5mZ
