export const baseUrl = "https://api.dev.exotalent.in";
export const socketUrl = "wss://api.dev.linkcxo.com";
export const lxBaseUrl = "https://api.dev.linkcxo.com";
export const token = `IaGilgUOLofOzbRuRKM5jUI70S1oORxWRGUMNPW8yYDLGbNmHfZOOodWZFGk55j7KsBOVr1jZgQuZPfN5NZniRtGoanyzNlgb7GDEc0nIJFtadtexBEptRJWE4vhWVDt`;

export const exouserAuthLocalBaseURL = "http://localhost:8081";
export const exoCompanyAuthLocalBaseURL = "http://localhost:8083";
export const exoRecruiterAuthLocalBaseURL = "http://localhost:8084";

export const exouserLocalBaseURL = "http://localhost:8080";
export const exoJobsLocalBaseURL = "http://localhost:8086";
export const exoCompanyLocalBaseURL = "http://localhost:8082";
export const exoRecruiterLocalBaseURL = "http://localhost:8085";

export const ADMIN = "Admin";
export const JOB_MANEGMENT = "Job Management";
export const CANDIDATE_MANEGMENT = "Candidate Management";

export const APPLICATION_SEND = "APPLICATION_SEND";
export const HOLD = "HOLD";
export const SHORTLISTED = "SHORTLISTED";
export const REJECTED = "REJECTED";
export const WITHDRAW = "WITHDRAW";
export const APPLIED = "APPLIED";

export const COMPANY_MEMBER = "COMPANY_MEMBER";
export const COMPANY = "COMPANY";
export const RECRUITER = "RECRUITER";
export const USER = "USER";

export const IND_COUNTRYCODE = {
  Id: 99,
  Iso2: "IN",
  CountryName: "India",
  Iso3: "IND",
  PhoneCode: "91",
  PhoneCodeLabel: "IND +91",
};

export const ITEMS = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Autoliv.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bosch.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Calsoft.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Jaguar Land Rover India.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Continuum.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Daimler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/DMG MORI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hindustan Platinum.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda power pack.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hyundai.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Inteva Products.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ITL-logo-01.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/John Deere.png",
  },
];

export const ITMES2 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bajaj Auto.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Aditya%20birla.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Cummins.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Eaton.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/GMR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ISS.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/MBRDI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/PVR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schindler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Wipro.png",
  },
];

export const ITEMS3 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Kone%20Crane.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Mahindra & Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Onward Technologies.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Rieter.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Satven.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schlumberger.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Sun Mobility.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tata Elxsi.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tech Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Weichai.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Yokogawa.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Zetwerk.png",
  },
];
