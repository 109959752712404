import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAssignMembersToJobApi,
  getJobDetailApi,
  updateJobStatusApi,
} from "../../slices/jobSlice";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { unwrapResult } from "@reduxjs/toolkit";

import useLocations from "../../utils/dropdown-options/useLocations";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import useJobTypes from "../../utils/dropdown-options/useJobTypes";
import useWorkPlaceTypes from "../../utils/dropdown-options/useWorkPlaceTypes";
import useMinExperiences from "../../utils/dropdown-options/useMinExperiences";

import LocationIcon from "../../assets/Icons/LocationIcon";
import moment from "moment";
import ClockIcon from "../../assets/Icons/ClockIcon";
import BellIcon from "../../assets/Icons/BellIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import { v4 as uuid4 } from "uuid";
import IndustryIcon from "../../assets/Icons/IndustryIcon";
import SalaryRangeIcon from "../../assets/Icons/SalaryRangeIcon";
import JobTypeIcon from "../../assets/Icons/JobTypeIcon";
import WorkPlaceIcon from "../../assets/Icons/WorkPlaceIcon";
import ExperienceIcon from "../../assets/Icons/ExperienceIcon";
import BulbIcon from "../../assets/Icons/BulbIcon";
import { baseUrl } from "../../constant";
import FilterIcon from "../../assets/Icons/FilterIcon";
import ShareIcon from "../../assets/Icons/ShareIcon";
import Popup from "../../components/popup/Popup";
import { getJobAuthour_CompanyDetailsApi } from "../../slices/companySlice";
import JobApplicants from "./components/JobApplicants";
import JobReferrals from "./components/JobReferrals";
import * as constants from "../../constant";
import AssignJobForm from "./components/AssignJobForm";
import CreateReferForm from "./components/CreateReferForm";
import { getJobAuthour_CompanyMemberApi } from "../../slices/companyMemberSlice";

let navigationButtons;

const JobPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const { defaultLocations } = useLocations();
  const { defualtSalary } = useSalaries();
  const { defaultIndustries } = useIndustries();
  const { defaultJobType } = useJobTypes();
  const { defaultWorkPlaceType } = useWorkPlaceTypes();
  const { defaultMinExperience } = useMinExperiences();

  const { jobId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [toggle, setToggle] = useState(0);
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleAssignJobPopup, setToggleAssignJobPopup] = useState(false);
  const [toggleCreateReferPopup, setToggleCreateReferPopup] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);
  const [toggleRefer, setToggleRefer] = useState(false);
  const [author, setAuthor] = useState(null);
  const [assignedMembers, setAssignedMembers] = useState(null);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleMore(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  useEffect(() => {
    const getJobById = async () => {
      try {
        const response = await dispatch(getJobDetailApi({ jobId })).then(
          unwrapResult
        );
        if (response) {
          if (response.data.data[0].authorType === constants.COMPANY) {
            try {
              const authorResponse = await dispatch(
                getJobAuthour_CompanyDetailsApi({
                  id: response.data.data[0].authorId,
                })
              ).then(unwrapResult);
              setJobDetails(response.data.data[0]);
              setIsLoading(false);
              setAuthor(authorResponse.data.data);
            } catch (error) {
              setIsLoading(false);
              setError(true);
            }
          } else if (
            response.data.data[0].authorType === constants.COMPANY_MEMBER
          ) {
            const responseMemberData = await dispatch(
              getJobAuthour_CompanyMemberApi({
                memberId: response.data.data[0].authorId,
              })
            ).then(unwrapResult);
            setAuthor(responseMemberData.data.data);
            setJobDetails(response.data.data[0]);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    };

    const getAssignedMembersByJobId = async () => {
      try {
        const response = await dispatch(
          getAssignMembersToJobApi({ jobId: jobId })
        ).then(unwrapResult);
        setAssignedMembers(response.data.data);
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    if (
      loginSelector.who === constants.COMPANY.toLowerCase() ||
      (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      getAssignedMembersByJobId();
    }

    getJobById();
  }, []);

  const loc = useMemo(() => {
    return !isLoading ? defaultLocations(jobDetails.location) : null;
  }, [jobDetails?.location]);

  const salMin = useMemo(() => {
    return !isLoading ? defualtSalary(jobDetails.salary.min) : null;
  }, [jobDetails?.salary?.min]);

  const salMax = useMemo(() => {
    return !isLoading ? defualtSalary(jobDetails.salary.max) : null;
  }, [jobDetails?.salary?.max]);

  const defaultInd = useMemo(() => {
    return !isLoading ? defaultIndustries(jobDetails?.industries) : null;
  }, [jobDetails?.industries]);

  const defaultJobTyp = useMemo(() => {
    return !isLoading ? defaultJobType(jobDetails?.jobType) : null;
  }, [jobDetails?.jobType]);

  const defaultworkPlaceTyp = useMemo(() => {
    return !isLoading ? defaultWorkPlaceType(jobDetails?.workplaceType) : null;
  }, [defaultWorkPlaceType, isLoading, jobDetails?.workplaceType]);

  const defaultMinExp = useMemo(() => {
    return !isLoading
      ? defaultMinExperience(jobDetails?.experience?.min)
      : null;
  }, [defaultMinExperience, isLoading, jobDetails?.experience?.min]);

  const defaultMaxExp = useMemo(() => {
    return !isLoading
      ? defaultMinExperience(jobDetails?.experience?.max)
      : null;
  }, [jobDetails?.experience?.max]);

  const postedBy = useMemo(() => {
    return !isLoading ? moment(jobDetails?.createdAt).fromNow() : null;
  }, [isLoading, jobDetails?.createdAt]);

  const createReferPopupHandler = () => {
    setToggleCreateReferPopup(!toggleCreateReferPopup);
    setToggleRefer(false);
  };

  const toggleHandler = (index) => {
    setToggle(index);
  };

  const toggleMoreHandler = () => {
    setToggleMore(!toggleMore);
    // setToggleRefer(false);
  };

  const toggleAssignJobPopupHandler = () => {
    setToggleAssignJobPopup(!toggleAssignJobPopup);
    // setToggleMore(false);
  };

  const closeJobHandler = async () => {
    try {
      const response = await dispatch(
        updateJobStatusApi({ jobId: jobDetails.id, status: "CLOSED" })
      ).then(unwrapResult);
      toggleMoreHandler();
      if (response) {
        if (loginSelector?.who === constants.COMPANY.toLowerCase())
          navigate("/company-admin");
        else if (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase())
          navigate("/member");
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const nevigateJobHandler = () => {
    navigate(`/update-job/${jobId}`);
  };

  navigationButtons = useMemo(() => {
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector?.designation === constants.ADMIN)
    ) {
      return [
        { title: "Job Description" },
        { title: "Candidate Lists" },
        { title: "Referral List " },
      ];
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector?.designation === constants.JOB_MANEGMENT
    ) {
      return [{ title: "Job Description" }];
    } else if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector?.designation === constants.CANDIDATE_MANEGMENT
    ) {
      return [
        { title: "Job Description" },
        { title: "Candidate Lists" },
        { title: "Referral List " },
      ];
    } else if (loginSelector?.who === constants.RECRUITER.toLowerCase()) {
      return [
        { title: "Job Description" },
        { title: "Candidate Lists" },
        { title: "Referral List " },
      ];
    }
  }, [loginSelector?.who, memberSelector?.designation]);

  if (error) {
    return <h1>Somthing went wrong</h1>;
  }

  console.log("loc :>> ", loc);

  return (
    !isLoading &&
    jobDetails &&
    author && (
      <div>
        <div className="w-[80%] mx-auto py-6 space-y-6">
          <div
            className="p-4 bg-exwhite-100 rounded-md flex items-center justify-between gap-2"
            style={{
              boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.7)",
            }}
          >
            <div className="h-28 w-28 bg-deafultCompanyProfile bg-cover bg-center bg-no-repeat"></div>

            <div className="flex-1 space-y-1">
              <div>
                <h1 className="text-xl text-expurple-600 font-semibold">
                  {jobDetails?.title}
                </h1>
              </div>

              <div>
                <p className="text-base text-expurple-800 font-semibold">
                  {jobDetails?.hideCompany &&
                  !jobDetails?.authorId === loginSelector?.userId
                    ? "Confidential"
                    : jobDetails?.company}
                </p>
              </div>

              <div className="flex items-center justify-start gap-10">
                <p className="flex items-center justify-start gap-1 text-base text-expurple-800 font-semibold line-clamp-1">
                  <LocationIcon width="20" height="20" color="#696969" />
                  {loc && loc.length > 0
                    ? loc.map(
                        (l, i) =>
                          `${l.Name} ${loc.length - 1 !== i ? ", " : ""}`
                      )
                    : "N/A"}
                </p>
                <p className="text-base text-expurple-800 flex items-center justify-start gap-2 leading-none font-semibold">
                  <ClockIcon width="20" height="20" color="#696969" />
                  {`Posted ${postedBy}`}
                </p>
              </div>
            </div>

            <div className="self-end space-y-4">
              <div className="w-fit mr-0 ml-auto">
                <BellIcon />
              </div>

              <div className="flex items-center justify-between gap-2 relative">
                {loginSelector?.who === constants.COMPANY.toLowerCase() ||
                loginSelector?.who === constants.RECRUITER.toLowerCase() ? (
                  <button
                    className="text-expurple-800 text-base text-center font-semibold px-4 py-2 bg-expurple-100 flex items-center justify-start rounded-sm gap-2"
                    onClick={createReferPopupHandler}
                  >
                    <ShareIcon />
                    Refer
                  </button>
                ) : loginSelector?.who ===
                    constants.COMPANY_MEMBER.toLowerCase() &&
                  (memberSelector.designation ===
                    constants.CANDIDATE_MANEGMENT ||
                    memberSelector.designation === constants.ADMIN) ? (
                  <button
                    className="text-expurple-800 text-base text-center font-semibold px-4 py-2 bg-expurple-100 flex items-center justify-start rounded-sm gap-2"
                    onClick={createReferPopupHandler}
                  >
                    <ShareIcon />
                    Refer
                  </button>
                ) : null}

                {loginSelector?.who === constants.COMPANY.toLowerCase() ? (
                  <button
                    className="text-expurple-800 text-base text-center font-semibold pr-4 pl-2 py-2 bg-expurple-100 flex items-center justify-start rounded-sm"
                    onClick={toggleMoreHandler}
                    ref={menuParentRef}
                  >
                    <VerticleThreeDotsIcon color="#223870" /> More
                  </button>
                ) : loginSelector?.who ===
                    constants.COMPANY_MEMBER.toLowerCase() &&
                  (memberSelector.designation === constants.JOB_MANEGMENT ||
                    memberSelector.designation === constants.ADMIN) ? (
                  <button
                    className="text-expurple-800 text-base text-center font-semibold pr-4 pl-2 py-2 bg-expurple-100 flex items-center justify-start rounded-sm"
                    onClick={toggleMoreHandler}
                    ref={menuParentRef}
                  >
                    <VerticleThreeDotsIcon color="#223870" /> More
                  </button>
                ) : null}

                {toggleMore && (
                  <div
                    className="absolute top-12 right-0 bg-expurple-200 border border-exgray-100 text-start rounded-sm flex flex-col items-center justify-center w-40 py-1"
                    style={{
                      boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.4)",
                    }}
                    ref={menuRef}
                  >
                    <button
                      className="py-2 text-base text-exgray-400/70 hover:text-expurple-800 hover:bg-expurple-700/30 w-full font-semibold"
                      onClick={nevigateJobHandler}
                    >
                      Job Edit
                    </button>
                    {loginSelector?.who === constants.COMPANY.toLowerCase() ? (
                      <button
                        className="py-2 text-base text-exgray-400/70 hover:text-expurple-800 hover:bg-expurple-700/30 w-full font-semibold"
                        onClick={toggleAssignJobPopupHandler}
                      >
                        Assign Job
                      </button>
                    ) : loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector.designation === constants.ADMIN ? (
                      <button
                        className="py-2 text-base text-exgray-400/70 hover:text-expurple-800 hover:bg-expurple-700/30 w-full font-semibold"
                        onClick={toggleAssignJobPopupHandler}
                      >
                        Assign Job
                      </button>
                    ) : null}
                    <button
                      className="py-2 text-base text-exgray-400/70 hover:text-expurple-800 hover:bg-expurple-700/30 w-full font-semibold"
                      onClick={closeJobHandler}
                    >
                      Close Job
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pb-2 border-b-2 border-b-exgray-100">
            {navigationButtons?.map((navogationBtn, index) => (
              <button
                key={uuid4()}
                className={`py-2 px-8 text-base ${
                  toggle === index
                    ? "bg-expurple-800 text-exwhite-100 rounded-md"
                    : "text-expurple-800"
                }`}
                onClick={() => toggleHandler(index)}
              >
                {navogationBtn.title}
              </button>
            ))}
          </div>

          {toggle === 0 && (
            <div className="flex items-center justify-center gap-2">
              <div className="flex-1 self-stretch whitespace-pre-wrap text-base text-exgray-200">
                {jobDetails?.description}
              </div>
              <div className="w-[40%] self-stretch space-y-2">
                <div className="border-2 border-exgray-100 p-6 rounded-md space-y-6">
                  <div className="grid grid-cols-2 auto-rows-auto gap-y-6">
                    <div className="text-start text-base space-y-1">
                      <div>
                        <IndustryIcon />
                      </div>
                      <h1 className="text-exgray-200 text-sm">INDUSTRY</h1>
                      {defaultInd && defaultInd.length > 0
                        ? defaultInd.map((l, i) => (
                            <p
                              className="text-expurple-800 font-semibold"
                              key={uuid4()}
                            >
                              {`${l.Name} ${
                                defaultInd.length - 1 !== i ? ", " : ""
                              }`}
                            </p>
                          ))
                        : "N/A"}
                    </div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <SalaryRangeIcon />
                      </div>
                      <h1 className="text-exgray-200 text-sm">SALARY RANGE</h1>
                      {jobDetails?.hideSalary &&
                      !jobDetails?.authorId === loginSelector?.userId ? (
                        <p className="text-expurple-800 font-semibold text-start">
                          Confidential
                        </p>
                      ) : (
                        <p className="text-expurple-800 font-semibold text-start">
                          &#x20b9; {salMin?.Title} - &#x20b9; {salMax?.Title}
                        </p>
                      )}
                    </div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <JobTypeIcon />
                      </div>
                      <h1 className="text-exgray-200 text-sm">JOB TYPE</h1>
                      <p className="text-expurple-800 font-semibold">
                        {defaultJobTyp?.Title}
                      </p>
                    </div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <WorkPlaceIcon />
                      </div>
                      <h1 className="text-exgray-200 text-sm">
                        WORK PLACE TYPE
                      </h1>
                      <p className="text-expurple-800 font-semibold">
                        {defaultworkPlaceTyp?.Title}
                      </p>
                    </div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <LocationIcon width="24" height="24" />
                      </div>
                      <h1 className="text-exgray-200 text-sm">LOCATION</h1>
                      <div className="flex flex-wrap items-center justify-start gap-1">
                        {loc && loc.length > 0
                          ? loc.map((l, i) => (
                              <p
                                className="text-expurple-800 font-semibold"
                                key={uuid4()}
                              >
                                {`${l.Name} ${
                                  loc.length - 1 !== i ? ", " : ""
                                }`}
                              </p>
                            ))
                          : "N/A"}
                      </div>
                    </div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <ExperienceIcon />
                      </div>
                      <h1 className="text-exgray-200 text-sm">
                        YRS OF EXPERIENCE
                      </h1>
                      <p className="text-expurple-800 font-semibold">
                        {defaultMinExp?.Title} - {defaultMaxExp?.Title}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="text-start text-base space-y-1">
                      <div>
                        <BulbIcon width="32" height="32" />
                      </div>
                      <h1 className="text-exgray-200 text-sm">SKILLS</h1>
                      <div className="text-expurple-800 font-semibold flex flex-wrap gap-2">
                        {jobDetails.skills.length > 0 &&
                          jobDetails.skills.map((skill) => (
                            <p
                              className="bg-expurple-800 px-3 py-2 text-start text-base text-exwhite-100 font-semibold rounded-md"
                              key={uuid4()}
                            >
                              {skill}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                {jobDetails.authorType === constants.COMPANY && (
                  <div className="p-6 border-2 border-exgray-100 rounded-md space-y-3">
                    <h1 className="text-exgray-800 text-xl font-semibold text-start">
                      Job Created By
                    </h1>
                    <div className="flex items-center justify-start gap-4">
                      {author?.profileImage ? (
                        <img
                          className="h-20 w-20 rounded-full object-cover"
                          src={`${baseUrl}/files/${author?.profileImage}?t=${token}`}
                          alt={`Profile`}
                        />
                      ) : (
                        <div className="h-10 w-10 rounded-full border-2 border-exgray-200 flex items-center justify-center bg-samplelogo bg-center bg-no-repeat bg-cover"></div>
                      )}
                      <h1 className="text-xl text-exgray-200 font-semibold">{`${author?.title}`}</h1>
                    </div>
                  </div>
                )}

                {jobDetails.authorType === constants.COMPANY_MEMBER && (
                  <div className="p-6 border-2 border-exgray-100 rounded-md space-y-3">
                    <h1 className="text-exgray-800 text-xl font-semibold text-start">
                      Job Created By
                    </h1>
                    <div className="flex items-center justify-start gap-4">
                      {author?.profileImage ? (
                        <img
                          className="h-20 w-20 rounded-full object-cover"
                          src={`${baseUrl}/files/${author?.profileImage}?t=${token}`}
                          alt={`Profile`}
                        />
                      ) : (
                        <div className="h-20 w-20 rounded-full flex items-center justify-center bg-samplelogo bg-center bg-no-repeat bg-cover"></div>
                      )}
                      <h1 className="text-xl text-exgray-200 font-semibold">{`${author?.firstName} ${author?.lastName}`}</h1>
                    </div>
                  </div>
                )}

                {assignedMembers && assignedMembers?.length > 0 && (
                  <div className="p-6 border-2 border-exgray-100 rounded-md space-y-3">
                    <h1 className="text-exgray-800 text-xl font-semibold text-start">
                      Assign Job to
                    </h1>

                    <div className="text-expurple-800 font-semibold flex flex-wrap gap-2">
                      {assignedMembers.map((member) => (
                        <p
                          className="bg-expurple-800 px-3 py-2 text-start text-base text-exwhite-100 font-semibold rounded-md"
                          key={uuid4()}
                        >
                          {member?.memberEmail}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {toggle === 1 && (
            <div className="">
              {/* <div className="w-fit mr-0 ml-auto">
                <button>
                  <FilterIcon />
                </button>
              </div> */}

              <div>
                <JobApplicants jobId={jobDetails.id} />
              </div>
            </div>
          )}

          {toggle === 2 && (
            <div className="space-y-4">
              {/* <div className="w-fit mr-0 ml-auto">
                <FilterIcon />
              </div> */}
              <div>
                <JobReferrals jobId={jobDetails.id} />
              </div>
            </div>
          )}
        </div>

        <Popup
          onClose={toggleAssignJobPopupHandler}
          visible={toggleAssignJobPopup}
        >
          <div className="w-[70%] bg-expurple-200 p-10 rounded-xl">
            <AssignJobForm
              onClose={toggleAssignJobPopupHandler}
              jobId={jobDetails.id}
            />
          </div>
        </Popup>

        <Popup
          onClose={createReferPopupHandler}
          visible={toggleCreateReferPopup}
        >
          <div className="w-[70%] bg-expurple-200 p-10 rounded-xl">
            <CreateReferForm
              onClose={createReferPopupHandler}
              jobId={jobDetails.id}
            />
          </div>
        </Popup>
      </div>
    )
  );
};

export default JobPage;
