import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constant from "../../../constant";
import LocationIcon from "../../../assets/Icons/LocationIcon";
import useLocations from "../../../utils/dropdown-options/useLocations";
import BellIcon from "../../../assets/Icons/BellIcon";
import { Link, useNavigate } from "react-router-dom";
import { getCompanyDetail } from "../../../slices/companySlice";

const CompanyDashboardProfile = () => {
  const dispatch = useAppDispatch();
  const { defaultLocation } = useLocations();
  const navigate = useNavigate();
  const loginSelector = useAppSelector((state) => state.login);
  const companySelector = useAppSelector((state) => state.company);
  const { companyDetails } = companySelector || {};
  const { id, bannerImage, profileImage, title, tagline, location } =
    companyDetails || {};

  const defaultLoc = useMemo(() => defaultLocation(location), [location]);

  useEffect(() => {
    dispatch(getCompanyDetail({ id: loginSelector?.session?.userId }));
  }, []);

  const navigationHandler = () => {
    navigate("/job-seekers");
  };

  return (
    companyDetails && (
      <div>
        {bannerImage ? (
          <div
            className="h-52 rounded-lg bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${constant.baseUrl}/files/${bannerImage}?t=${loginSelector?.session?.token})`,
            }}
          ></div>
        ) : (
          <div className="h-52 bg-companyDefaultBanner rounded-lg bg-cover bg-center bg-no-repeat"></div>
        )}

        <div
          className="w-[98%] bg-expurple-200 mx-auto -mt-10 rounded-lg flex items-center justify-between p-5 gap-4"
          // style={{
          //   boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.7)",
          // }}
        >
          {profileImage ? (
            <div
              className="h-32 w-32 rounded-lg bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${constant.baseUrl}/files/${profileImage}?t=${loginSelector?.session?.token})`,
              }}
            ></div>
          ) : (
            <div className="h-32 w-32 rounded-lg bg-deafultCompanyProfile bg-cover bg-center bg-no-repeat"></div>
          )}

          <div className="flex-1">
            <h1 className="text-xl text-expurple-800 font-semibold">{title}</h1>
            <h2 className="text-lg text-expurple-700 font-semibold">
              {tagline}
            </h2>
            <div className="flex items-center justify-start gap-1">
              <LocationIcon color="#242525" />
              <p className="text-base text-expurple-700 font-semibold">
                {defaultLoc?.Name}
              </p>
            </div>
          </div>

          <div className="self-start space-y-8">
            <div className="flex items-center justify-center gap-8">
              <div className="cursor-pointer">
                <BellIcon />
              </div>
              <Link to={`/compnay-profile-edit/${id}`}>
                <button className="text-lg text-expurple-700 font-semibold tracking-wide">
                  Edit Profile
                </button>
              </Link>
            </div>
            <div className="w-fit ml-auto mr-0">
              <button
                className="px-6 py-2 text-base text-expurple-800 border-2 border-expurple-800 rounded-md font-semibold w-fit  hover:bg-expurple-800 hover:text-exwhite-100"
                onClick={navigationHandler}
              >
                Candidate Pool
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CompanyDashboardProfile;
