import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import JobCard from "../../job-search/components/JobCard";
import Popup from "../../../components/popup/Popup";
import JobDetail from "../../job-search/components/JobDetail";
import { getAppliedJobApi } from "../../../slices/jobSlice";

const AppliedJobList = () => {
  const dispatch = useAppDispatch();
  const appliedJobs = useAppSelector((state) => state.jobs.appliedJobs);
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  const jobData = useAppSelector((state) => state.jobs);
  const { jobCards, isLoading, error } = jobData;

  console.log("appliedJobs :>> ", appliedJobs);

  useEffect(() => {
    dispatch(getAppliedJobApi());
  }, []);

  const togglePopupHandler = (jobId) => {
    setTogglePopup(!togglePopup);
    setSelectedJobId(jobId);
  };

  const closePopupHandler = (jobId) => {
    setTogglePopup(false);
    setSelectedJobId("");
  };

  return (
    <div>
      {appliedJobs && appliedJobs?.length > 0 && (
        <div className="flex flex-wrap mx-auto">
          {appliedJobs &&
            appliedJobs?.length > 0 &&
            appliedJobs.map((appliedJob) => (
              <div className="w-[33.3333333%]" key={uuid4()}>
                <div onClick={() => togglePopupHandler(appliedJob.job.id)}>
                  <JobCard job={appliedJob.job} jobApplication={appliedJob} />
                </div>
                <Popup
                  onClose={closePopupHandler}
                  key={uuid4()}
                  visible={appliedJob.job.id === selectedJobId}
                >
                  {appliedJob.job.id === selectedJobId && (
                    <div className="bg-expurple-200 p-10 rounded-xl w-[75%] h-[90%] overflow-y-auto no-scrollbar">
                      <JobDetail
                        jobDetails={appliedJob.job}
                        applied={true}
                        applicationId={appliedJob.id}
                        closePopupHandler={closePopupHandler}
                      />
                    </div>
                  )}
                </Popup>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AppliedJobList;
